@import "variables";

cx-promotions .cx-promotions {
  padding: 5px 0 !important;

  ul {
    padding: 0;
    margin: 0;
  }

  p, ul > li {
    color: $primary;
    font-size: 13px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 16px !important;
    padding: 0;
    margin: 0;
  }
}

cx-cart-item-list {
  cx-promotions {
    display: block;

    &.bundle .cx-promotions {
      left: 44px;
    }

    .cx-promotions {
      left: 6.5rem;
      position: absolute;
      padding: 0;
      bottom: 0;

      @media(max-width: $screen-sm-max) {
        left: 6rem;
      }
    }
  }
}
