favorite-products-added-to-cart-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .favorite-products-added-to-cart-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    .favorite-products-added-to-cart-dialog-container {
      @extend .modal-content;

      border-radius: 12px;

      .favorite-products-added-to-cart-dialog-header {
        border-bottom: none;
        padding-bottom: 0;

        .favorite-products-added-to-cart-dialog-close {
          font-size: 1.5rem;
          margin-left: auto;
          top: 5px;
        }
      }

      .favorite-products-added-to-cart-dialog-body {
        @extend .modal-body;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 10px;

        .cart-icon {
          align-self: center;
          margin-bottom: 24px;
          width: 67px;
        }
      }

      .confirm-message {
        display: flex;
        font-size: 1.5rem;
        font-weight: 500;
        padding: 0 2rem;
        text-align: center;
        text-transform: capitalize;
      }

    }

    .favorite-products-added-to-cart-dialog-footer {
      display: flex;
      margin-top: 24px;

      a {
        flex: 0 0 calc(50% - 0.5rem);
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 24px;
        padding: 12px;

        &:first-child {
          margin-inline-end: 0.5rem;
        }

        &:last-child {
          margin-inline-start: 0.5rem;
        }
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;

        a:first-child {
          margin: 0 !important;
        }

        a:last-child {
          margin: 0 0 16px 0 !important;
        }
      }
    }
  }
}