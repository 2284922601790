body {
  .fa-times-circle {
    font-weight: 600;
    vertical-align: middle;

    &:before {
      content: "\f00d";
    }
  }

  .edit-icon {
    &:before {
      display: block;
      content: url("/assets/icons/edit_black_24.svg");
      width: 24px;
      height: 24px;
    }
  }

}
