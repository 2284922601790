@import '../styles/variables';

body {

  button.search {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  div.search-icon {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.searchbox-is-active {
    &.has-searchbox-results {
      generac-searchbox {
        .results {
          display: block;
        }
      }

      header {
        position: relative;
        z-index: 100;
      }

      cx-storefront {
        &:before {
          content: '';
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;

          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &:not(.searchbox-is-active) {
    @include media-breakpoint-down(sm) {
      generac-searchbox {
        input {
          width: 0;
          padding: 0;
        }

        button.reset,
        .sp-search-menu {
          display: none;
        }
      }
    }
  }
}

generac-searchbox {
  z-index: 10;

  &>* {
    background: transparent;
    color: $dark-grey;
    min-height: 38px;
    border-radius: 6px;

    @media(max-width: $screen-sm-max) {
      border: none;
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;
  }

  display: block;

  >* {
    z-index: 20;

    @include media-breakpoint-up(md) {
      background-color: var(--cx-color-inverse);
      position: relative;
    }
  }

  .list-link,
  .message {
    padding: 6px 16px;
    color: currentColor;
    user-select: none;
  }

  .sp-search-message {
    background: $white;
    border: 1px solid $border-light-grey;
    border-radius: 6px;
    margin-top: -0.75rem;
  }

  .searchbox {
    align-items: center;
    display: flex;
    align-content: stretch;
    margin: 0;
    min-width: 200px;
    max-width: 500px;
    width: 30em;
    height: 50px;
    padding: 0;

    @media(max-width: $screen-sm-max) {
      min-width: auto;
      max-width: initial;
      width: auto;
    }

    @media(min-width: $screen-md) {
      border: 1px solid var(--cx-color-medium);
      border-radius: 6px;
      width: 37vw;
      min-width: 300px;
      max-width: 500px;

      &.dirty div.search-icon {
        display: none;
      }
    }

    @media(min-width: $screen-lg) {
      width: 53vw;
    }

    input {
      background: none;
      border: none;
      color: inherit;
      outline: none;
      display: block;
      height: 50px;
      padding: 6px;

      @include media-breakpoint-down(sm) {
        position: absolute;
        left: 0;
        top: 57px;
        width: 100%;
        background-color: $white-background;
        padding: 6px 16px;
        height: 48px;
        border-bottom: 1px solid var(--cx-color-light);
      }

      flex-basis: 100%;
      height: 35px;
      z-index: 20;

      @include placeholder {
        color: currentColor;
      }
    }

    &:not(.dirty) button.reset {
      display: none;
    }

    button,
    div.search-icon {
      flex-basis: 48px;
      text-align: center;
      background: none;
      border: none;
      padding: 6px;
      color: var(--cx-color-medium);

      @include media-breakpoint-down(sm) {
        color: var(--cx-color-primary);
        font-size: var(--cx-font-size, 1.563rem);
      }

      &.reset cx-icon {
        &:before {
          font-size: 1.4rem;
        }

        @include media-breakpoint-down(sm) {
          position: relative;
          left: 74px;
          z-index: 20;
          top: 50px;
          color: $dark-grey;
          margin-top: 0;
        }
      }

      &.reset.has-sp-selector cx-icon {
        @include media-breakpoint-down(sm) {
          left: -65px;
        }

        @include media-breakpoint-down(xs) {
          left: -15px;
        }
      }
    }

    .sp-search-menu {
      border-left: 1px solid $border-light-grey;
      border-radius: 0 6px 6px 0;
      height: 100%;
      min-width: 125px;
      max-width: 150px;
      width: inherit;

      @include media-breakpoint-down(sm) {
        border: none;
        min-width: 0;
        max-width: 0;
        width: 0; 
      }

      &-opened {
        background-color: $light-grey-background;
        border-radius: 0 6px 0px 0;

        button {
          color: $secondary !important;
        }
      }

      button {
        align-items: center;
        color: $dark-grey;
        display: flex;
        height: 100%;
        justify-content: space-around;
        width: 100%;

        @include media-breakpoint-down(sm) {
          border-left: 1px solid $border-light-grey;
          position: relative;
          left: -60px;
          font-size: 1rem;
          z-index: 20;
          top: 52px;
          margin-top: 0;
          width: 160px;
        }

        @include media-breakpoint-down(xs) {
          left: -15px;
          width: 120px;
        }

        .model-name {
          color: $secondary !important;
          display: inline-block;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          width: calc(80%);
          white-space: nowrap;
        }
      }
    }
  }

  .sp-searchbox-opened {
    border-radius: 6px 6px 0 0;
  }

  .sp-search-results {
    position: absolute;
    z-index: 100000;
    width: 100%;
    background: $light-grey-background;
    border-radius: 0 0 6px 6px;
    border: 1px solid $border-grey;
    border-top: none;

    @include media-breakpoint-down(sm) {
      top: 105px;
      left: 0;
    }

    &-tabs {
      .desktop-tab-paragraph-container {
        border-bottom: 1px solid $border-grey;
        padding: 0 0.75rem;
        height: 56px;
        max-width: 100% !important;

        .desktop-tab-paragraph {
          &-container {
            display: flex;
            flex-direction: column;
          }

          &-tabs {
            display: flex;
            justify-content: flex-start !important;
            list-style-type: none;
            margin: 0;
            scrollbar-width: none;
            padding: 0.75rem 0 0;
            position: absolute;
            overflow-x: auto;
            overflow-y: hidden;
            overflow-x: -moz-scrollbars-none;
            -ms-overflow-style: none;
            width: calc(100% - 48px);

            ::-webkit-scrollbar {
              display: none;
              width: 0 !important;
            }
          }

          &-tab {
            &:last-child {
              label {
                margin-right: 0 !important;
              }
            }

            input[type="radio"] {
              visibility: hidden;
              display: none;
            }

            a {
              min-height: 0;
            }

            label {
              font-family: $primary-font;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5rem;
              letter-spacing: 0;
              color: #000000;
              padding: 4px 20px 1rem;
              margin: 0;
              margin-right: 40px;
              width: max-content !important;
              text-align: center;
              transition: all 0.15s ease-in-out;
              position: relative;

              &:hover {
                cursor: pointer;
                color: $primary;
              }

              &.active {
                color: $primary;

                &::after {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 4px;
                  bottom: -1px;
                  left: 0;
                  background-color: $primary;
                }
              }
            }
          }

          &-content {
            display: block;
          }
        }
      }
    }

    &-searchbox {
      background: $light-grey-background;
      padding: 1rem 0.75rem 0;

      label.searchbox {
        background: $white-background;
        border-radius: 4px;
        height: 40px;
        width: auto;

        div.search-icon {
          flex-basis: 44px;
        }

        .search {
          color: $dark-grey;

          @include media-breakpoint-down(sm) {
            font-size: 1rem;
          }
        }

        &>button.reset {
          @include media-breakpoint-down(sm) {
            display: block;
            font-size: 1rem;

            cx-icon {
              left: 0;
              top: 0;
            }
          }
        }

        &:not(.dirty) button.reset {
          display: none;
        }

        &.dirty .search {
          display: none;
        }

        input {
          font-size: 13px;
          height: 40px;

          @include media-breakpoint-down(sm) {
            border-bottom: none;
            position: relative;
            top: 0px;
          }
        }
      }
    }

    .results {
      background-color: $light-grey-background;
      display: block;
      position: relative;
      padding: 0.75rem;
      max-height: 230px;
      overflow: auto;
      border: none;

      @include media-breakpoint-down(sm) {
        position: relative;
        top: 0;

        .products {
          display: block;
        }
      }

      ul {
        background-color: $white-background;
        border-radius: 4px;
        border: 1px solid $border-grey;

        a {
          border-top: none;
        }
      }

      &.results-hidden {
        height: 0;
        visibility: hidden;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.75rem;

      button {
        font-size: 0.875rem;
        width: 50%;

        @include media-breakpoint-down(sm) {
          font-size: 0.75rem;
        }

        &.btn-secondary {
          background-color: $white-background;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .results {
    // hide the result by default
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    color: #000;
    background-color: #fff;
    font-size: var(--cx-font-small, 1rem);
    border-radius: 6px;
    border: 1px solid $border-grey;

    @include media-breakpoint-down(sm) {
      top: 105px;
      z-index: 10;
    }

    a {
      text-decoration: none;
      cursor: pointer;

      font-weight: 800;

      .highlight,
      .search-results-highlight {
        font-weight: normal;
        font-style: normal;
      }

      &:hover,
      &:focus {
        background: var(--cx-color-light);
        color: currentColor;
      }
    }

    .suggestions {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-inline-start: 0;
      margin-bottom: 0;

      li,
      a {
        flex: 100%;
        line-height: 2rem;
        display: block;
      }
    }

    .products {
      list-style: none;
      padding-inline-start: 0;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      a {
        display: grid;
        grid-column-gap: 16px;

        &.has-media {
          // create a grid layout in case we show an image
          grid-template-columns: 50px 1fr;
        }

        border-top: solid 1px var(--cx-color-light);

        cx-media, .sp-gear-thumb {
          grid-row: 1 / 3;
        }

        .sp-gear-thumb {
          margin: auto;
          width: 40px;
        }

        div.name {
          text-overflow: ellipsis;

          font-size: inherit;
          font-weight: 500;

          // Required for text-overflow to do anything
          white-space: nowrap;
          overflow: hidden;
        }

        .price {
          font-weight: normal;
        }
      }
    }
  }
}