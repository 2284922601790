@import "variables";

cx-contact-factory-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .cfd {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;
    width: 100%;

    &:not(.ty) {
      max-width: 852px;
    }

    &.ty {
      max-width: 630px;

      .cfd-container {
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-space {
      margin-top: 24px;
    }

    &-container {
      @extend .modal-content;

      border-radius: 12px;
      padding: 32px;

      .cfd-header {
        line-height: 32px;
        padding: 0 0 32px 0;

        .close {
          color: $dark-grey;
          font-size: 24px;
          opacity: 1;
          right: -10px;
        }

        &-title {
          font-size: var(--cx-font-size, 1.375rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: 32px;
        }
      }

      .cfd-body {
        @extend .modal-body;
        padding: 24px 0;

        form {
          .cfd-body-block:last-child {
            border: none;
            padding-bottom: 0;
          }
        }

        &-top {
          display: flex;
          flex-direction: column;

          &-header {
            font-size: 18px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 4px;
          }

          &-description {
            font-size: 14px;
            line-height: 28px;
            color: $dark-grey;

            //span {
            //  color: $primary;
            //}
          }
        }

        &-block {
          padding-bottom: 24px;
          border-bottom: 1px solid #dee2e6;

          &-row {
            display: flex;
            gap: 36px;
          }

          &-label, &-textarea {
            width: 100%;
            margin-bottom: 0;

            .input-label {
              display: block;
              margin-bottom: 15px;
            }

            span {
              line-height: 19px;

              //&:first-child:not(.input-label) {
              //  margin-right: 24px;
              //}
            }

            input {
              padding: 10.5px;
              height: 42px;
            }

            textarea {
              padding: 10.5px;
              min-height: 86px;
              max-height: 86px;
            }
          }

          &-label {
            max-width: 50%;
          }
        }
      }

      .required {
        &:after {
          content: " *";
          position: relative;
          color: $red;
        }
      }

      .form-control {
        margin: 0;
        background: transparent;
      }

      cx-form-errors {
        position: absolute;

        p {
          line-height: normal;
          padding: 0;
          margin: 0;
          color: var(--cx-color-danger);

          &:before, &:after {
            display: none;
          }
        }
      }

      textarea:focus, input:focus {
        outline: none;
      }

      .cfd-footer {
        display: flex;
        gap: 36px;

        button {
          width: 50%;
        }
      }

      .cfd-ty {
        &-header {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        &-text {
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          color: $dark-grey;
        }

        &-close {
          position: absolute;
          font-size: 24px;
          top: 28px;
          right: 28px;
          bottom: auto;
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .cfd {
      &:not(.ty), &.ty {
        max-width: fit-content;
      }

      &-container {
        padding: 16px;

        .cfd-header {
          padding: 0 0 16px 0;
        }

        .cfd-body {
          padding: 16px 0;

          &-top-description a {
            min-height: unset;
            min-width: unset;
          }

          &-block {
            &-label {
              max-width: unset;
            }

            &-row {
              flex-direction: column;
              gap: 16px;
            }
          }
        }

        .cfd-footer {
          gap: 16px
        }
      }

      &-space {
        margin-top: 16px;
      }
    }
  }
}