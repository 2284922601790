@import "variables";

.OrderConfirmationPageTemplate {
  .BodyContent {
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    max-width: $container-width;
    margin: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 5fr 2fr;
    grid-column-gap: 2rem;

    cx-order-confirmation-thank-you-message {
      grid-row: 1;
      grid-column: 1 / 3;
      display: inline-grid;

      .cx-page-header {
        width: 100%;
        position: absolute;
        left: 0;
      }

      .cx-order-confirmation-message {
        padding: 7rem 2.5rem 0 2.5rem;
      }
    }

    cx-order-details-shipping {
      grid-row: 2;
      grid-column: 1 / 3;
      display: inline-grid;

      generac-order-overview .cx-order-summary .container {
        max-width: $container-width;
        margin: auto;
        min-width: min-content;
        padding: 15px 0;
      }

      .mat-accordion .mat-expansion-panel {
        .mat-expansion-panel-header {
          flex-direction: row-reverse;
          gap: 0.5rem;

          &.mat-expanded {
            border-bottom: 1px solid $border-grey;
            border-radius: 0;
          }
          
          .mat-expansion-indicator {
            height: 26px;
          }
        }

        .mat-expansion-panel-content .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }

    cx-order-confirmation-shipping {
      display: none;
    }

    cx-order-confirmation-totals {
      grid-column: 2;
      grid-row: 3;
      margin-top: 2rem;

      .cx-order-summary {
        background: $grey-background;
        border-radius: 8px;
        padding: 1rem;
      }
    }

    cx-order-confirmation-items {
      grid-row: 3;
      grid-column: 1 / 2;

      .cx-order-items-header {
        border: none;
      }
    }
  }


  @media(max-width: $screen-md-max) {
    .BodyContent {
      > * {
        padding: 0 1rem;
      }

      cx-order-confirmation-items {
        grid-column: 1 / 3;
      }

      cx-order-confirmation-totals {
        grid-row: 4;
        grid-column: 1 / 3;
        width: 100%;
        min-width: 300px;
      }

      cx-export-order-entries {
        grid-row: 5;
      }

      .cx-order-summary {
        .container {
          background-color: unset;

          .cx-summary-card {
            background-color: unset;
          }
        }
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    cx-breadcrumb {
      background: transparent;
    }
    .BodyContent {
      cx-order-confirmation-thank-you-message {
        .cx-order-confirmation-message p {
          margin: 0;
        }
      }

      cx-order-confirmation-items {
        padding: 0 20px;

        .cx-order-items-header {
          padding: 32px 0;
        }

        cx-cart-item-list table {
          margin: 0;
        }
      }

      .cx-order-summary {
        .container {
          background-color: $grey-background;
          border-radius: 10px;
          padding: 0 20px !important;

          .cx-summary-card {
            border: none;
            margin: 0;
            padding: 0;

            &:last-child {
              cx-card:last-child {
                .cx-card {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      cx-order-confirmation-totals {
        border-radius: 10px;
        padding: 20px;
        background: whitesmoke;
      }
    }
  }
}