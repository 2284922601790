add-to-favorites-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .add-to-favorites-dialog {

    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    .add-to-favorites-dialog-container {
      @extend .modal-content;

      border-radius: 12px;

      .add-to-favorites-dialog-header {
        border-bottom: none;
        padding-top: 2rem;
        padding-bottom: 0.75rem;

        .add-to-favorites-dialog-title {
          font-size: var(--cx-font-size, 1.375rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: var(--cx-line-height, 1.2222222222);
          margin-inline-start: 15px;
        }

        .add-to-favorites-dialog-close {
          bottom: 0;
          font-size: 16px;

          img {
            width: 32px;
          }
        }
      }

      .add-to-favorites-dialog-body {
        @extend .modal-body;

        padding: 0 32px 32px;

        .info-message-block {
          background: $grey-background;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          padding: 8px 0;
          margin-bottom: 20px;

          .info-message-icon {
            width: 18px;
          }

          .info-message-text {
            color: $dark-grey;
            font-size: 0.875rem;
            margin-left: 4px;
          }
        }

        .select-list-text {
          font-size: 0.875rem;
          margin-bottom: 10px;
        }

        .existing-lists-of-favorites {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          max-height: 415px;
          overflow: auto;
        }

        .list-item {
          border: 1px solid $border-grey;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 14px 16px;
          margin: 6px 0;

          .form-check {
            margin-bottom: 0;
            width: 100%;

            .form-check-label {
              line-height: 28px;
              top: 3px;
              width: 100%;
            }

            .checkbox {
              border-color: $border-grey;
              border-radius: 4px;
              box-shadow: none;
              height: 24px;
              outline: none;
              margin-top: 4px;
              margin-inline-start: -100%;
              width: 24px;
              -webkit-margin-start: -100%;

              &:checked {
                border-color: $primary;

                &::after {
                  height: 9px;
                  width: 16px;
                  top: 3px;
                  left: 2px;
                }
              }
            }
          }

          .label-indicator {
            border-radius: 4px;
            font-size: 0.875rem;
            min-width: 82px;
            padding: 4px 8px;
            line-height: 20px;
            text-align: center;

            &.added {
              background: rgba(57, 181, 74, 0.05);
              color: $green;
            }

            &.removed {
              background: rgba(167, 48, 40, 0.05);
              color: #E35147;
            }
          }
        }
      }

      .add-list-wrapper {
        display: flex;
        justify-content: space-between;

        .add-list-input {
          background: $white;
          border-radius: 6px;
          border: 1px solid $border-grey;
          height: 42px;
          margin-right: 12px;
          outline: none;
          padding: 5px 12px;
          width: 100%;
        }

        .add-list-btn {
          border-width: 2px;
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          padding: 9px 16px;

          &:disabled {
            background-color: transparent;
            border-color: $primary;
            color: $primary;
            opacity: 0.65;
          }
        }
      }

    }

    .add-to-favorites-dialog-footer {
      display: flex;

      button {
        flex: 0 0 calc(50% - 0.5rem);

        &:first-child {
          margin-inline-end: 0.5rem;
        }

        &:last-child {
          margin-inline-start: 0.5rem;
        }

        &:focus {
          @include visible-focus();
        }
      }
    }
  }
}