/* You can add global styles to this file, and also import other style files */

$styleVersion: 6.8;
@import '@spartacus/styles/index';
@import "styles/home";
@import 'styles/header';
@import 'styles/product';
@import 'styles/product-details';
@import 'styles/checkout';
@import 'styles/cart';
@import "styles/variables";
@import "styles/general";
@import "styles/icons";
@import "styles/buttons";
@import "styles/footer";
@import "styles/spartacus/verify-address";
@import "styles/added-to-cart-dialog";
@import "styles/order-confirmation";
@import "styles/carousel";
@import "styles/bundle";
@import "styles/saved-cart";
@import "styles/alert";
@import "styles/favorites-list";
@import "styles/add-to-favorites-dialog";
@import "styles/create-or-edit-favorites-list-dialog";
@import "styles/favorites-confirm-action-dialog";
@import "styles/favorite-products-added-to-cart-dialog";
@import "styles/promotions";
@import "styles/order-overview";
@import "styles/animations";
@import "styles/saved-addresses";
@import "styles/edit-saved-address-dialog";
@import "styles/delete-saved-address-dialog";
@import "styles/searchbox";
@import "styles/facet-list";
@import "styles/facet";
@import "styles/active-facets";
@import "styles/contact_factory_dialog";
@import "styles/checkout-out-of-stock-dialog";
@import "styles/split-payment-dialog";
