@import "variables";

main cx-page-layout {
  cx-cart-item-list {
    cx-media, .sp-gear-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .cx-item-list-row.cx-item-list-subscription-item {
      display: contents;

      & > tr.cx-item-list-row > td {
        padding-bottom: 0;
      }

      & > tr:not(.top-header), & > tr:not(.bottom-footer) {
        border: none !important;
        border-left: 1px solid $border-grey;
        border-right: 1px solid $border-grey;
      }
    }

    .table-subscription-row-header {
      position: relative;
      border-radius: 10px 10px 0 0;

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 1px);
        height: 140px;
        top: 10px;
        left: -.5px;
        z-index: -1;
      }

      &.top-header {
        border-top: 0;

        &::after {
          border-radius: inherit;
          border-top: 1px solid $border-grey;
          border-left: 1px solid $border-grey;
          border-right: 1px solid $border-grey;
        }
      }
    }

    .table-subscription-row-footer {
      position: relative;
      border-radius: 0 0 10px 10px;

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 1px);
        height: 140px;
        bottom: 0;
        left: -.5px;
        z-index: -1;
      }

      &.bottom-footer {
        border-bottom: 0;

        &::after {
          border-radius: inherit;
          border-bottom: 1px solid $border-grey;
          border-left: 1px solid $border-grey;
          border-right: 1px solid $border-grey;
        }
      }
    }

    .cx-item-list-row.bundle-item {
      border-bottom: 1px solid $border-grey;
    }

    .cx-item-list-row.cx-item-list-bundle-item {
      display: contents;

      & > tr:not(.top-header) {
        border-left: 1px solid $border-grey;
        border-right: 1px solid $border-grey;
      }
    }

    .cx-table-bundle-item {
      grid-template-columns: 19rem !important;
      text-align: left;
    }

    .table-bundle-row-header {
      position: relative;
      border-radius: 6px 6px 0 0;

      p {
        padding: 6px 20px 6px 20px;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
      }

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 1px);
        height: 100%;
        top: 0;
        left: -1px;
        z-index: -1;
        background-color: $grey-background;
      }

      &.top-header {
        border-top: 0;

        &::after {
          border-radius: inherit;
          border-top: 1px solid $border-grey;
          border-left: 1px solid $border-grey;
          border-right: 1px solid $border-grey;
        }
      }
    }
  }

  .cx-sorting.bundles-sorting {
    border-style: solid;
    border-color: var(--cx-color-light);

    @include media-breakpoint-down(md) {
      border: none;
    }

    &.bottom {
      border-width: 1px 0 0 0;
      padding: 25px 0 0 0;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: $screen-md-max) {
  main cx-page-layout {
    cx-cart-item-list {
      .cx-table-bundle-item {
        grid-template-columns: auto;
      }
    }
  }
}

