@import "variables";
@import "mixins";

.ProductGridPageTemplate,
.SearchResultsListPageTemplate,
cx-product-carousel-item,
cx-product-references,
generac-product-accessories,
favorites-list {
  display: flex;
  height: 100%;
  position: relative;

  .cx-product-layout>.fas {
    color: $primary;
  }

  cx-product-grid-item,
  cx-product-list-item {
    min-height: 100%;
    position: relative;
    width: 100%;

    .cx-product-image-container {
      display: flex;
      align-items: center;
      height: 290px;
      justify-content: center;
    }

    .cx-product-image {
      max-width: 255px;
      height: 255px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cx-product-name {
      overflow: unset;
      word-break: break-word;
    }

    .cx-product-rating {
      margin-top: auto;
    }

    .call-for-price {
      display: flex;
      max-width: max-content;
    }

    .cx-product-price-container {
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .cx-product-price {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .contact-us {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 6px 20px;
        background: $grey-background;
        border-radius: 6px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    .product-ltl, .product-flammable {
      cx-info-message > .info-message-container {
        bottom: 45px;
        max-width: 250px;
        right: -90px;

        @media(max-width: $screen-sm-max) {
          right: -20px;

          &:after {
            right: 15px;
          }
        }

        cx-icon {
          display: none;
        }
      }
    }

    .product-flammable cx-info-message > .info-message-container {
      max-width: 230px;
    }
  }

  cx-product-grid-item {
    add-to-favorites {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .product-ltl {
      background: $white;
      position: absolute;
      right: 25px;
      top: 60px;
      padding: 2px 0;
    }

    .product-flammable {
      background: $white;
      position: absolute;
      right: 25px;
      top: 100px;
      padding: 2px 0;
    }
  }

  .call-for-pricing {
    height: 48px;
    font-size: 18px !important;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  cx-active-facets {
    a cx-icon:hover {
      filter: contrast(1.1) brightness(1.1);
    }
  }

  add-to-favorites {
    background: $white;
    border-bottom-left-radius: 6px;

    .btn {
      min-height: 24px;
      min-width: 24px;
      padding: 7px;

      cx-icon svg,
      .cx-icon svg {
        width: 24px;
        height: 24px;
      }

      .button-text {
        display: none;
      }
    }
  }

  cx-product-list {
    @media (max-width: $screen-md-max) {
      .cx-sorting.top, .cx-sorting.bottom {
        display: grid;

        .pagination-bottom {
          order: 3;
        }

        .cx-sort-dropdown {
          align-items: flex-start;
          flex-direction: column;
          height: auto;
          max-width: calc(100% - 80px);
          order: 1;
        }

        .pagination-block {
          display: none;
        }

        .product-view-block {
          align-self: flex-end;
          margin-bottom: 1rem;
          order: 2;
        }
      }
    }

    .product-list-header {
      border-bottom: 1px solid $grey-background;

      @media (max-width: $screen-sm-max) {
        display: none;
      }

      th {
        font-size: 0.75rem;
        font-weight: 400;
        padding: 10px 0;
      }

      .product-title {
        width: 26%;
      }

      .product-price {
        width: 7%;
      }

      .product-quantity {
        width: 12%;
      }

      @media (max-width: $screen-md-max) {
        .product-title {
          width: 18%;
        }
      }
    }

    .cx-product-container {
      margin: 10px 0;
    }

    .cx-sorting.top {
      .sp-nested-searchbox-container {
        position: relative;

        .sp-nested-searchbox {
          border: 1px solid $border-grey;
          border-radius: 4px;
          color: $dark-grey;
          height: 100%;
          padding: 0.75rem;
          width: 100%;
        }

        .search-icon {
          cursor: pointer;
          display: flex;
          top: 8px;
          right: 20px;
          padding: 8px;
          z-index: 1;
          position: absolute;
          background: $white;

          cx-icon {
            color: $dark-grey;
          }
        }
      }
    }

    .cx-sort-dropdown {
      .ng-arrow-wrapper {
        width: 10px;
        padding-inline-end: 5px;
      }
    }

    .sp-sorting {
      @media (min-width: $screen-md) {
        max-width: 200px;
      }
    }

    cx-product-list-item {
      margin: 2px 0 2px 0;
      padding-bottom: 0;

      .product-name-container a>.name {
        max-height: 53px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      cx-add-to-cart {
        form {
          align-items: center;
          display: flex;
          gap: 20px;

          generac-product-badge {
            .info {
              border-radius: 4px !important;
              font-size: 0.75rem !important;
              line-height: 0.75rem !important;
              margin-left: 0 !important;
              margin-top: 0 !important;
              padding: 6px 10px !important;
            }
          }

          .quantity {
            margin: 0;

            label {
              display: none;
            }
          }

          .is-icon {
            height: 44px;
            width: 44px;

            img {
              height: 22px !important;
            }
          }

          .is-mobile-btn {
            height: 44px;
            width: auto;

            img {
              height: 22px !important;
            }
          }

          .quantity-add-to-cart-block {
            display: flex;
            gap: 20px;

            @media (max-width: $screen-sm-max) {
              width: 100%;

              .btn {
                width: 100%;
              }
            }
          }

          cx-custom-item-counter {
            .cx-custom-item-counter {
              margin: 0;
              width: 90px;

              .cx-custom-item-counter__input {
                border: none;
                max-height: 42px;
              }

              .cx-custom-item-counter__btn {
                align-items: center;
                font-size: 1rem;
                max-height: 42px;
              }

              .info-message-container.position-left {
                left: -150px !important;
                width: 260px !important;

                &::after {
                  left: 175px !important;
                }
              }
            }
          }
        }

        generac-sp-flammable, generac-sp-ltl {
          display: none;
        }
      }

      .border-grey {
        border: 1px solid $border-grey;
      }

      .favorite-placeholder {
        border: none;
      }

      add-to-favorites {
        @include display-flex-center;
        border-radius: 2px;
        height: 44px;
        width: 44px;
        right: 0;
      }

      .non-price {
        flex-direction: row-reverse;
        gap: 20px;
        max-width: 370px;

        &.service-parts {
          max-width: 370px;
          width: 100%;
          margin: auto 0 auto 2rem;
          justify-content: space-between;
        }

        &:not(.service-parts) {
          margin-right: 0 !important;
        }

      }

      .cx-product-price-container add-to-favorites {
        right: 5px;
        top: 13px;
      }

      @media (max-width: $screen-sm-max) {
        add-to-favorites {
          &:not(.favorite-placeholder) {
            border: 1px solid $border-grey;
          }
        }

        .non-price-status {
          cx-info-price-wrapper {
            order: 2;
          }
        }

        cx-custom-item-counter {
          .info-message-container.position-left {
            left: -60px !important;

            &::after {
              left: 85px !important;
            }
          }
        }
      }

      .info-message-container.position-left {
        left: -115px;
      }
    }
  }

  .ProductGridPageTemplate {
    flex-wrap: wrap;

    @media (max-width: $screen-md-max) {
      cx-product-list .cx-sorting {
        border-style: solid;
        border-color: var(--cx-color-light);
      }

      .cx-page-section {
        padding-top: 0;
      }
    }
  }

  .ProductGridAdditionalSlot {
    width: 100%;
    margin-top: 10px;
  }

  .SearchResultsListPageTemplate {
    @media (min-width: $screen-md) {
      .SearchResultsListSlot {
        margin-left: -10px;
      }
    }

    @media (max-width: $screen-md-max) {
      .SearchResultsListSlot {
        width: 96%;
        max-width: none;
        margin: auto;
      }
    }

    cx-product-grid-item {
      padding-bottom: 1rem;

      & > * {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .sp-plp-table {
      border: 1px solid $border-grey;
      padding: 0;

      @media (max-width: $screen-md-max) {
        padding: 0 15px;
      }

      .cx-sorting.top,
      .cx-sorting.bottom {
        padding: 0 1.5rem 0px;
      }

      .cx-sorting.bottom {
        padding-top: 1rem;
      }

      .product-list-header {
        padding-left: 1.5rem;
      }

      .cx-product-container {
        margin: 0;

        cx-product-list-item {
          border-color: $grey-background;

          @media (min-width: $screen-md) {
            padding: 0 1.5rem;
          }
        }
      }

      .sp-plp-tabs {
        margin-bottom: 1.5rem;

        .desktop-tab-paragraph-container {
          border-bottom: 1px solid $border-grey;
          padding: 0 1.5rem;
          height: 64px;
          max-width: 100% !important;

          .desktop-tab-paragraph {
            &-container {
              display: flex;
              flex-direction: column;
            }

            &-tabs {
              display: flex;
              justify-content: flex-start !important;
              list-style-type: none;
              margin: 0;
              scrollbar-width: none;
              padding: 1rem 0 0;
              position: absolute;
              overflow-x: auto;
              overflow-y: hidden;
              overflow-x: -moz-scrollbars-none;
              -ms-overflow-style: none;
              width: calc(100% - 48px);

              ::-webkit-scrollbar {
                display: none;
                width: 0 !important;
              }
            }

            &-tab {
              &:last-child {
                label {
                  margin-right: 0 !important;
                }
              }

              input[type="radio"] {
                visibility: hidden;
                display: none;
              }

              a {
                min-height: 0;
              }

              label {
                font-family: $primary-font;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0;
                color: #000000;
                padding: 4px 0 20px;
                margin: 0;
                margin-right: 80px;
                width: max-content !important;
                text-align: center;
                transition: all 0.15s ease-in-out;
                position: relative;

                &:hover {
                  cursor: pointer;
                  color: $primary;
                }

                &.active {
                  color: $primary;

                  &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    bottom: -1px;
                    left: 0;
                    background-color: $primary;
                  }
                }
              }
            }

            &-content {
              display: block;
            }
          }
        }
      }

      .no-results {
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        min-height: 200px;

        &-text {
          color: $secondary;
          font-size: 1.25rem;
          line-height: 2.5rem;
          text-transform: capitalize;
        }
      }
    }
  }
}