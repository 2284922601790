edit-saved-address-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .edit-saved-address-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    max-width: 740px;

    .edit-saved-address-dialog-container {
      @extend .modal-content;

      border-radius: 12px;
      padding: 32px;

      .edit-saved-address-dialog-header {
        border-bottom: none;
        padding: 0;

        .close {
          color: $dark-grey;
          font-size: 24px;
          opacity: 1;
          right: -10px;
        }

        .edit-saved-address-dialog-title {
          font-size: var(--cx-font-size, 1.375rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: var(--cx-line-height, 1.2222222222);
        }
      }

      .edit-saved-address-dialog-body {
        @extend .modal-body;

        padding: 32px 0;

        .form-group {
          .ng-select-container, .form-control {
            height: 42px;
          }

          .ng-value-container {
            line-height: 40px;
            .ng-value {
              height: 40px;
            }
          }

          .ng-input {
            top: 0;
          }

          .ng-select:focus, input:focus, .ng-select-focused {
            outline: none;
          }
        }
      }

      .edit-saved-address-dialog-footer {
        display: flex;

        button {
          flex: 0 0 calc(50% - 0.5rem);

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;

          button:first-child {
            margin: 0 !important;
          }
  
          button:last-child {
            margin: 0 0 16px 0 !important;
          }
        }
      }
    }
  }
}