@import "variables";

body {
  font-family: $primary-font;
  overflow-x: hidden;
  position: relative;

  a,
  button.link {
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &.modal-open {
    cx-global-message {
      z-index: 1100;
      position: fixed;
      width: 100%;
    }
  }

  .cx-page {
    padding-bottom: 0px;
  }

  button.link {
    text-decoration: none;
  }

  cx-item-counter {
    button {
      font-size: 26px;
      display: flex;
      justify-content: center;
      height: 48px;

      &:hover {
        color: $primary;
      }

      &:disabled {
        color: $disabled;
      }
    }
  }

  cx-breadcrumb {
    background: $grey-background;
    padding-bottom: 24px;

    .breadcrumbs-title {
      align-items: center;
      display: flex;
      justify-content: center;

      h1 {
        line-height: 42px;
        margin: 0;
      }

      cx-offline-badge {
        .offline-badge {
          height: 28px;
          padding: 6px 8px;
          margin-top: 2px;

          .offline {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            margin-right: 0;
          }

          cx-icon {
            transform: scale(0.6668);
            padding: 0;
          }

          cx-info-message {
            .info-message-container.position-right {
              font-size: 14px;
              height: 52px;
              right: 0;
              top: 40px;
              max-width: 280px;
              padding: 2px 8px;
              color: $black;

              &::after {
                right: 20px;
                bottom: 51px;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    h1 {
      font-size: 24px;
      margin: auto;
      white-space: pre-wrap;

      @media(min-width: $screen-xs) {
        max-width: $container-width;
        padding: 0 15px 0 15px;
      }
    }

    a {
      @media(max-width: $screen-md-max) {
        min-height: auto;
      }
    }

    .model-number {
      text-align: center;
      margin-bottom: -20px;
      color: var(--cx-color-secondary);
      font-size: 0.875rem;
    }
  }

  .ProductDetailsPageTemplate {
    .BottomHeaderSlot {
      cx-breadcrumb {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        h1 {
          font-weight: 400;
          max-width: 50%;
          padding: 0px 0px 0px;
          font-size: 14px;
          margin: 2px 5px 0 0;
          height: 20px;
          text-align: left;
          color: #6c7079;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 1;
          box-orient: vertical;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          @media (max-width: $screen-md-max) {
            display: none;
          }

          &::before {
            content: '/';
            margin-right: 5px;
          }
        }
      }
    }
  }

  cx-cart-item,
  cx-custom-cart-item {
    .cx-compact {
      .cx-price,
      .cx-total {
        .cx-value {
          font: 700 16px/24px $primary-font;
        }
      }

      .cx-actions {
        button.link {
          display: flex;
          align-items: center;
        }
      }
    }


    .product-ltl, .product-flammable {
      cx-info-message > .info-message-container {
        bottom: 45px;
        max-width: 250px;
        right: -90px !important;

        @media(max-width: $screen-sm-max) {
          right: -20px;

          &:after {
            right: 15px;
          }
        }

        cx-icon {
          display: none;
        }
      }
    }

    .product-flammable cx-info-message > .info-message-container {
      max-width: 230px;
    }
  }

  cx-scroll-to-top {
    button {
      background: $primary;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        filter: contrast(1.1) brightness(1.1);
      }
    }
  }

  cx-anonymous-consent-management-banner {
    .anonymous-consent-banner {
      left: 0;
      border-top: 3px solid $primary;
      left: 0;
    }
  }

  .cx-item-list-header {
    border: none;
  }

  .hide {
    display: none;
  }

  .form-control {
    border: 1px solid $light-grey;

    &:disabled {
      background-color: #f9f9f9;
    }
  }

  .form-control::placeholder {
    font: 300 16px/20px $primary-font;
    color: $dark-grey;
  }

  generac-address-form form {
    background-color: transparent;
  }

  main {
    z-index: 1;
    flex: 1;
  }

  .table-terms-and-conditions {
    table,
    th,
    td {
      padding: 10px;
      border: 1px solid black;
      border-collapse: collapse;
    }
  }

  .cancellation-terms-and-conditions {
    font-size: 0.7rem;
  }

  .justify-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .renewal-select {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          font: 300 16px/20px $primary-font;
          color: $dark-grey;
        }
      }
    }

    .ng-value {
      & > * {
        margin-right: 10px;
      }
    }

    .ng-dropdown-header {
      display: flex;
      justify-content: space-between;
      font: 700 12px/14px $primary-font;
      color: $grey-text;
    }


    .ng-dropdown-panel-items {
      .ng-option {
        display: flex;
        justify-content: space-between;
        font: 400 14px/16px $primary-font;

        .option-first-name,
        .option-last-name {
          width: 33%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.spinner-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 50%);
}

.spinner-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pointer-none {
  pointer-events: none;
}

.regular-icon {
  color: $secondary;

  svg {
    width: 24px;
    height: 24px;
  }

  &.active {
    color: $primary;
  }

  &.grey {
    color: $dark-grey;
  }

  &.blue {
    color: $dark-blue;
  }

  &:hover {
    color: $primary;
  }

  &.not-active {
    pointer-events: none;
  }

  &.small {
    svg {
      width: 20px;
      height: 20px;
    }
  }

}

.favorite-icon {
  color: transparent;
  stroke: $rare;

  &.active {
    color: $primary;
    stroke: $primary;
  }

  &:hover {
    stroke: $primary;
  }

  &.not-active {
    pointer-events: none;
  }

  &.small {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.text-crossed {
  color: $dark-grey;
  text-decoration: line-through !important;
}

.anchor {
  content: "";
  display: block;
  position: relative;
  visibility: hidden;

  &.offset {
    height: 200px;
    margin-top: -200px;
  }
}


.hide-overflow-x-start {
  mask: linear-gradient(90deg, #0000, #000 12% 100%, #0000);
}

.hide-overflow-x-end {
  mask: linear-gradient(90deg, #0000, #000 0 88%, #0000);
}

.hide-overflow-x {
  mask: linear-gradient(90deg, #0000, #000 12% 88%, #0000);
}

.text-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 80%);

  &-container {
    padding: 24px;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid $regular-border;

    &-header {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      margin-bottom: 30px;
    }

    &-btns {
      display: flex;
      gap: 24px;

      button {
        width: 50%;
      }
    }
  }
}


.text-class {
  .ng-value-container {
    width: 100%;

    .ng-value {
      width: 100%;

      .bold-title {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.sp-note-link {
  display: inline-block !important;
  color: $primary !important;
  padding: 0;
}

cx-review-submit, cx-order-confirmation-items {
  .order-review-shipping-group .order-review-shipping-group-special-handling .card-body {
    padding: 0 1.25rem;
  
    .cx-card-title {
      color: $black;
      font-size: 1rem !important;
      font-weight: 500 !important;
      line-height: 1.25rem;
      margin-bottom: 0.75rem;
    }
  
    .cx-card-label {
      color: $black;
    }
  }
}