@import "variables";

saved-addresses {
	.saved-address-items {
		border-bottom: 1px solid $border-grey;
		padding-bottom: 30px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;

		@media(max-width: $screen-md-max) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media(max-width: $screen-sm-max) {
			grid-template-columns: repeat(1, 1fr);
		}

		saved-address-item {
			justify-self: center;
			max-width: 352px;
			width: 100%;

			.saved-address-item-wrap {
				align-content: space-around;
				border: 1px solid $border-grey;
				border-radius: 10px;
				height: 100%;
				flex-direction: column;
				display: flex;
				justify-content: space-between;

				.saved-address-item-info-wrap {
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					height: 100%;

					.saved-address-item-info {
						padding: 20px 20px 0 20px;
						min-height: 190px;
	
						p {
							display: flex;
							font-size: 0.875rem;
							font-weight: 500;
							line-height: 20px;
							margin-bottom: 12px;
	
							.field-name {
								color: $dark-grey;
								display: inline-block;
								text-align: right;
								margin-right: 1rem;
								width: 24%;
							}
	
							.field-value {
								color: $secondary;
								width: 70%;
							}
						}
					}

					.delivery-residential {
						color: $primary;
						display: flex;
						align-items: center;
						gap: 12px;
						margin-bottom: 10px;
						justify-content: center;
					}
				}

				.saved-address-item-actions {
					border-top: 1px solid $border-grey;
					display: flex;
					justify-content: space-around;
					padding: 12px;

					button {
						color: $primary;
						background: none;
						border: none;
						font-weight: 500;
						font-size: 0.875rem;

						cx-icon {
							svg {
								width: 20px;
								height: 28px;
								transform: scale(0.85);
							}
						}

						&:disabled {
							color: $border-grey;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}

	.add-address-wrap {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		padding: 30px 0 0;

		@media(max-width: $screen-md-max) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media(max-width: $screen-sm-max) {
			grid-template-columns: repeat(1, 1fr);
		}

		.add-address-btn {
			box-shadow: none;
			height: 44px;
			margin: auto;
			max-width: 352px;
			width: 100%;

			&:disabled {
				background-color: $border-grey;
				cursor: not-allowed;

				&:hover {
					filter: contrast(1) brightness(1);
				}
			}
		}
	}
}