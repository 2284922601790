@import "variables";

main .LandingPage2Template {
  cx-page-slot.Section1 {
    margin-top: 0;
  }

  .Section2A,
  .Section2B {
    cx-banner {
      margin-top: 70px;
    }
  }

  .Section3 {
    .dealer-acquisition {
      margin-top: 52px;

      add-to-favorites {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
    }

    cx-bundle-carousel {
      margin-top: 52px;
    }
  }
}

@media(max-width: $screen-xs-max) {
  main .LandingPage2Template {
    .Section2A,
    .Section2B {
      cx-banner {
        margin-top: 30px;
      }
    }

    .Section3 {
      cx-bundle-carousel {
        margin: 30px 0;
      }
    }
  }
}

