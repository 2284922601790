@import "variables";

favorites-list {
    display: flex;
    flex-direction: row;

    @media (max-width: $screen-xs-max) {
        flex-direction: column;
    }

    favorites-navigation {
        width: 243px;

        @media (max-width: $screen-xs-max) {
            width: 100%;

            .favorites-navigation-items {
                display: none;

                &.popup {
                    background: $white;
                    display: block;
                    position: fixed;
                    height: 100vh;
                    left: 0;
                    max-height: none !important;
                    top: 0;
                    width: 100%;
                    z-index: 11;

                    .cross-icon {
                        display: flex;
                        padding: 16px;
                    }

                    .popup-title {
                        background-color: $border-grey;
                        display: flex;
                        font-size: 19px;
                        font-weight: 400;
                        line-height: 24px;
                        padding: 20px 16px;
                        margin-bottom: 4px;
                        width: 100%;
                    }

                    .favorites-navigation-item {
                        margin: 10px 16px !important;
                    }

                    .context-menu {
                        right: 5%;
                    }
                }
            }

            .favorites-navigation-items-mobile {
                align-items: center;
                display: flex !important;
                justify-content: space-between;
                pointer-events: all;

                .selected-item {
                    border: 1px solid $border-grey;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    margin: 10px 0;
                    padding: 8px;
                    width: 85%;

                    .count {
                        background-color: $grey-background;
                        border-radius: 4px;
                        color: $dark-grey;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;
                        padding: 3px 6px;
                        margin-right: 12px;
                    }

                    span {
                        cursor: pointer;
                    }

                    .item-actions {
                        background: none;
                        border: none;
                        float: right;
                        margin-left: auto;
                        padding-right: 0;
                    }

                    .context-menu {
                        margin-top: 42px !important;
                    }
                }

                .show-favorites-lists-popup-mobile {
                    border: 1px solid $border-grey;
                    border-radius: 4px;
                    background: none;
                    height: fit-content;
                    padding: 9px;
                }
            }

            .add-new-list-block {
                border-top: none !important;
                border-bottom: 1px solid $border-grey;
                margin-top: 0 !important;
                padding: 24px 0;
            }
        }

        .favorites-navigation-wrapper {
            display: flex;
            flex-direction: column;
            padding-top: 15px;

            .favorites-navigation-title {
                font-size: 1.25rem;
                font-weight: 500;
                margin-bottom: 16px;

                cx-icon {
                    margin-right: 12px;
                }
            }

            .favorites-navigation-items {
                max-height: 530px;
                overflow: auto;

                .favorites-navigation-item {
                    border-radius: 4px;
                    cursor: pointer;
                    margin: 10px 0;
                    padding: 8px;

                    &.list-active {
                        background-color: $grey-background;
                    }

                    .count {
                        background-color: $grey-background;
                        border-radius: 4px;
                        color: $dark-grey;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;
                        padding: 3px 6px;
                        margin-right: 12px;

                        &.count-active {
                            background-color: $white;
                        }
                    }

                    span {
                        cursor: pointer;
                    }

                    .item-actions {
                        background: none;
                        border: none;
                        float: right;
                        padding-right: 0;
                    }
                }
            }

            .favorites-navigation-items-mobile {
                display: none;
            }

            .context-menu {
                align-items: flex-end;
                border-radius: 2px;
                border: 1px solid #EEE;
                background: $white;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 24px;
                padding: 16px 20px;
                position: absolute;
                z-index: 1;

                @media (max-width: $screen-xs-max) {
                    margin-left: 0;
                    right: 15%;
                }

                .remove-list,
                .rename-list {
                    color: $dark-grey;
                    background: none;
                    border: none;
                    line-height: 32px;
                    text-align: left;
                    width: 100%;
                }
            }

            .add-new-list-block {
                border-top: 1px solid $border-grey;
                margin-top: 20px;
                padding-top: 20px;

                .add-new-list-btn {
                    color: $primary;
                    font-weight: 500;
                    background: none;
                    border: none;

                    img {
                        margin-right: 12px;
                        vertical-align: sub;
                    }
                }
            }

        }
    }

    .favorites-wrapper {
        margin-left: 3rem;
        width: 100%;

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }

        @media ((min-width: $screen-xs) and (max-width: $screen-md-max)) {
            margin-left: 22px;
        }

        .favorites-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
                font-weight: 400;
                margin-bottom: 20px;
                margin-top: 10px;
            }

            .remove-all-btn {
                background: none;
                border: none;
                font-weight: 500;

                @media (max-width: $screen-md-max) {
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 194px;
                }

                img {
                    vertical-align: top;
                }
            }


            @media (max-width: $screen-xs-max) {
                align-items: center;
                margin-top: 28px !important;
                margin-bottom: 20px;

                h1 {
                    margin: 0;
                }
            }
        }

        .favorite-items {
            padding-left: 15px;
            width: 100%;

            .favorite-items-top {
                align-items: center;
                border: 1px solid $border-grey;
                border-bottom: none;
                display: flex;
                justify-content: flex-end;
                margin: 0 -15px;
                padding: 15px;

                @media (max-width: 669px) {
                    flex-direction: column;

                    .cx-sort-dropdown {
                        flex-direction: column;
                        margin-right: 0 !important;
                        margin-bottom: 1rem !important;
                        width: 100%;
                        
                        & > span {
                            width: 100%;
                        }

                        cx-sorting {
                            width: 100%;
                            margin-left: auto;
                        }
                    }

                    .add-all-to-cart {
                        align-self: flex-end;
                    }
                }

                .cx-sort-dropdown {
                    align-items: center;
                    display: flex;
                    gap: 8px;
                    margin-bottom: 0;
                }

                .add-all-to-cart {
                    justify-self: flex-end;

                    @media (max-width: 669px) {
                        width: 100%;
                    }
                }
            }

            &>.row {
                border: 1px solid $border-grey;
                padding: 15px 0;

                cx-product-grid-item {
                    min-height: auto;
                    position: relative;
                    width: 33%;
                }
            }

            cx-add-to-cart {
                .stock-info {
                    display: none;
                }

                @media(max-width: 590px) {
                    .btn-primary {
                        font-size: 1rem;
                    }
                }
            }

            .pagination-bottom {
                border: 1px solid $border-grey;
                border-top: none;
                display: flex;
                justify-content: flex-end;
                padding: 24px 16px;
                margin: 0 -15px;
            }
        }

        .no-favorites {
            align-items: center;
            border: 1px solid $border-grey;
            display: flex;
            flex-direction: column;
            height: 95%;
            justify-content: center;
            margin: auto;
            min-height: 400px;
            padding: 0 20%;
            text-align: center;
            width: 100%;

            @media (max-width: $screen-xs-max) {
                padding: 0 12px;
            }

            .favorites-icon {
                background-color: $grey-background;
                border-radius: 50%;
                padding: 16px;

                .favorite-icon svg {
                    transform: scale(1.5);
                    width: 24px;
                    height: 24px;
                }
            }

            h1 {
                color: $secondary;
                font-weight: 700;
                padding: 24px;
                line-height: 42px;
            }

            .favorites-text {
                color: $dark-grey;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;

                img {
                    width: 32px;
                }
            }

            .btn-primary {
                margin-top: 40px;
                max-width: 300px;
                width: 300px;
            }
        }
    }
}