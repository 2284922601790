@import "variables";

cx-added-to-cart-dialog {

  generac-carousel {
    margin-bottom: 20px;

    cx-product-grid-item {
      height: 100%;

      .cx-product-image-container {
        display: none;
      }

      .cx-product-name {
        font-weight: 500;
        line-height: 24px;
        height: auto !important;
        max-height: none !important;
      }

      .cx-product-price-container {
        padding-bottom: 8px !important;

        .cx-product-price {
          margin: 12px;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
        }
      }

      cx-add-to-cart {
        .favorites-block {
          display: none;
        }
      }
    }

    .slides {
      padding-top: 16px;
    }

    .generac-carousel-wrapper {
      padding: 24px 0 0 0 !important;

      .carousel-panel {
        margin: 0 !important;

        .desktop-tab-paragraph-container .desktop-tab-paragraph-tab label {
          margin-right: 30px !important;
        }
      }

      &::after {
        width: calc(100% + 64px) !important;
        height: calc(100% + 24px) !important;
      }

      .generac-carousel-header {
        margin-bottom: 20px;
        padding: 0 !important;
      }

      > .generac-carousel-header {
        h2 {
          flex: 1;
          font-size: 16px !important;;
          font-weight: 500;
          line-height: 24px !important;
        }
      }
    }
  }

  .warning-no-return {
    margin-bottom: 32px;
  }

  .cx-modal-container {
    min-width: $added-cart-desktop-modal-width !important;
    max-width: $added-cart-desktop-modal-width !important;
  }
}

@media (max-width: $added-cart-desktop-modal-width - 1) {
  cx-added-to-cart-dialog {
    .cx-modal-container {
      max-width: calc(100% - 32px) !important;
      min-width: calc(100% - 32px) !important;
      margin: 30px 16px !important;
      height: auto;
    }
  }
}

@media (max-width: $screen-md-max) {
  cx-added-to-cart-dialog {
    display: flex;

    generac-carousel {
      .generac-carousel-wrapper {

        > .generac-carousel-header {
          flex-direction: column;
          align-items: flex-start !important;

          .generac-carousel-controls {
            margin-top: 16px;

            .previous {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
}