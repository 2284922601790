checkout-out-of-stock-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .checkout-out-of-stock-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    .checkout-out-of-stock-dialog-container {
      @extend .modal-content;

      border-radius: 12px;

      .checkout-out-of-stock-dialog-header {
        border-bottom: none;
        padding-bottom: 0;

        .checkout-out-of-stock-dialog-close {
          font-size: 1.5rem;
          margin-left: auto;
          top: 5px;
        }
      }

      .checkout-out-of-stock-dialog-body {
        @extend .modal-body;
        padding-top: 10px;

        .confirm-message {
          display: flex;
          font-size: 1.5rem;
          font-weight: 500;
          text-align: center;
        }

        .info-message {
          color: $dark-grey;
          display: flex;
          font-size: 1rem;
          font-weight: 400;
          justify-content: center;
          line-height: 32px;
          margin-top: 16px;
          text-align: center;
        }
      }

      .checkout-out-of-stock-dialog-footer {
        display: flex;
        margin-top: 24px;

        button {
          flex: 0 0 calc(50% - 0.5rem);

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;

          button:first-child {
            margin: 0 !important;
          }
  
          button:last-child {
            margin: 0 0 16px 0 !important;
          }
        }
      }
    }
  }
}