@import "variables";

main {
  cx-page-layout.CartPageTemplate {
    margin-top: 30px;

    cx-page-slot.CenterRightContentSlot {
      border-radius: 10px;
      display: grid;
      margin-left: 20px;
      top: 30px;
      height: fit-content;
      --cx-flex-basis: 26%;
      margin-bottom: 20px;

      @media(max-width: $screen-md-max) {
        margin-left: 0;
        margin-top: 10px;
      }

      @media(max-width: $screen-sm-max) {
        flex: 0 0 100% !important;
        flex-direction: column;
        max-height: none;
      }

      cx-cart-totals {
        padding: 20px 20px 0;
      }

      .desired-date-label-container {
        .info-message-container {
          right: -92px;
          bottom: 40px;
          width: max-content;
        }
      }

      cx-cart-coupon {

        .form-group {
          margin-bottom: 0;
        }

        .cx-cart-coupon-title {
          display: none;
        }

        .cx-cart-coupon-container {
          grid-column-gap: 12px;

          .input-coupon-code {
            border: none;
            border-radius: 6px;
            height: 42px;
            margin-bottom: 0;

            &:focus {
              background: $white;
            }
          }
          .apply-coupon-button {
            border-width: 2px;
            font-weight: normal;
            height: 42px;
            min-height: 42px;
            padding: 9px 16px;
          }

          .btn-secondary:disabled {
            background-color: $white;
            border: 2px solid $primary;
            color: $primary;
            filter: contrast(1) brightness(1);
            opacity: 1;
          }
        }

        .coupon-error {
          .coupon-error-text {
            color: $red;
            font-size: 13px;
            margin-left: 8px;
          }
        }

        cx-applied-coupons {
          display: block;
          margin-top: 16px;

          @media(max-width: $screen-sm-max) {
            .cx-coupon-card-grid {
              flex: 0 0 100% !important;
            }
          }
          .cx-coupon-list-wrap {
            margin-bottom: 0;
          }

          .cx-coupon-apply {
            background: #D3F5D8;
            border-radius: 8px;
            font-weight: 500;
            padding: 10px 12px;
            margin-top: 8px;

            .close {
              margin-top: -5px;

              .cx-icon {
                font-size: 16px;
              }
            }
          }
        }
      }

      cx-schedule-replenishment-order {
        grid-row: 3;

        .cx-order-type-card {
          border-radius: 0;
          margin-bottom: 0px;
          padding: 10px 20px;
        }

        .cx-label-container {
          border-top: 1px solid $border-grey;
          padding-top: 20px;
        }
        
        .cx-replenishment-form-data-container {
          margin: 0px;
          display: flex;
          flex-direction: column;
          width: 100%;

          .cx-days {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .form-data-label {
            align-self: start;
          }

          &.desired {
            padding-top: 10px;

            .form-data-label {
              padding-bottom: 5px;
            }

            .cx-replenishment-date {
              width: 100%;
            }
          }
        }

        .cx-replenishment-date {
          width: 90%;

          input {
            padding: 0.6875rem 0.15rem;
            font-size: 1rem;
            height: 48px;
            width: 100%;
          }
        }
      }

      cx-cart-quick-order-form {
        background: $grey-background;
        border-radius: 10px;
        grid-row: 5;
        padding: 0 20px 40px;

        .btn-action {
          &.disabled {
            border-width: 1px;
          }
        }
      }

      cx-cart-proceed-to-checkout {
        background: $grey-background;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        grid-row: 4;
        margin-bottom: 12px;
        padding: 16px 20px 20px;
      }

      @media(max-width: $screen-sm-max) {

        cx-cart-proceed-to-checkout {
          width: 100%;
        }
      }

      cx-custom-order-summary {
        background: $grey-background;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        grid-row: 2;
        padding: 20px 20px 10px;
      }
    }

    .TopContent {
      flex-direction: row;
      flex-wrap: wrap;

      cx-cart-item-list {
        td:nth-of-type(1) {
          width: 20vw;
        }

        td:not(:nth-of-type(1)) {
            font-size: inherit;
        }

        .cx-name {
          min-height: 48px;
          display: flex;
          align-items: center;
          margin-top: 0;
        }

        .cx-code {
          margin: 0.75rem 0;
        }

        .bundle-main-item {
          @media(max-width: $screen-md-max) {
            td:nth-child(n+2) {
              margin-left: 32px;
            }
            .text-crossed {
              margin-right: 10px;
            }
          }
        }

        .sp-table-row > td:last-of-type {
          padding: 1.25rem 1rem 1.25rem 0.5rem;
        }
      }

      .cx-availability {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        color: $red;

        &:before {
          content: url("/assets/icons/do_not_disturb_red_24.svg");
          width: 24px;
          margin-right: 5px;
        }
      }

      .cx-quantity.cx-mobile-only {
        align-items: center;
      }

      .cx-remove-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media(max-width: $screen-md-max) {
          justify-content: flex-start;
          margin-inline-start: 0;
          padding: 0;
        }
      }

      cx-promotions {
        display: block;
        margin-top: 0.5rem;
      }

      cx-import-export-order-entries {
        display: flex;
        flex: 70%;
        align-self: center;
        margin: 0;
        order: 3;
      }

      cx-clear-cart {
        display: flex;
        flex: 50%;
        order: 1;
        margin-top: 0;
        max-height: 30px;

        @media(max-width: $screen-sm-max) {
          flex: 33%;
        }

        .clear-cart-wrapper {
          margin: 0;

          .clear-cart-btn {
            min-height: 0;
          }
        }
      }

      cx-cart-details {
        order: 2;
        min-height: 80%;
        position: relative;

        .cart-details-wrapper {
          padding: 0;

          .header {
            padding-top: 4rem;
          }
        }

        cx-cart-item-list {
          margin-bottom: 40px;
          display: block;
        }

        cx-clear-cart {
          position: absolute;
          right: 0;
          bottom: -32px;     
        }
      }

      cx-add-to-saved-cart {
        display: flex;
        flex: 50%;
        order: 1;
        max-height: 30px;

        @media(max-width: $screen-sm-max) {
          flex: 66%;
        }

        .cx-add-to-saved-cart-container {
          justify-content: flex-start;
          margin-top: 0;
        }
      }

      @media(max-width: $screen-sm-max) {
        .cx-add-to-saved-cart-container {
          margin-top: 0;
          
        }

        cx-cart-item-list {
          tr {
            padding: 10px;
          }
        }
      }
    }
  }
}
