// Colors
$primary: var(--cx-color-primary);
$secondary: #242729;
$black: #000000;
$dark-grey: #666666;
$light-grey: #d3d6db;
$white: #fff;
$green: #39B54A;
$red: #A73028;
$coral: #F5DFDA;
$disabled: #d3d6db;
$red-note: #DB3D3D;
$rare: #6B6B6B;

$grey-background: #F5F5F5;
$light-blue-background: #EFF3FC;
$dark-blue: #181D9B;
$white-background: #fff;
$dark-grey-background: #d3d6db;
$green-background: rgba(57, 181, 74, 0.05);
$warning-background: #F5ECDA;
$warning-background: #FFF3DC;
$light-grey-background: #F1F1F1;
$regular-border: #dee2e6;

$grey-text: #C8C8C8;

$border-light-grey: #cccccc;
$border-grey: #d8d8d8;

$grey-shadow: #D9D9D9;

// Fonts
$primary-font: "Helvetica Neue", Arial, sans-serif;

// Media breakpoints
$screen-xxs: 390px;
$screen-xxs-max: ($screen-xxs - 1);

$screen-xs: 480px;
$screen-xs-max: ($screen-xs - 1);

$screen-sm: 768px;
$screen-sm-max: ($screen-sm - 1);

$screen-md: 992px;
$screen-md-max: ($screen-md - 1);

$screen-lg: 1200px;
$screen-lg-max: ($screen-lg - 1);

$container-width: 1140px;
$added-cart-desktop-modal-width: 876px;
