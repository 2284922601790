@import "variables";

@mixin hideWrapperDesktop {
  display: none;
}

@mixin showWrapperDesktop {
  display: initial;
}

header {
  background: linear-gradient(to top, $grey-background 0, $grey-background 72px, $white-background 72px);
  border-bottom: 4px solid $primary;

  cx-header-navigation.flyout > nav > ul > li {

    & > button + .wrapper {
      box-shadow: 0 4px 4px 0 #00000040;
      border: none;
      min-width: 220px;
      margin-inline-start: 0;
      top: -10px;
      padding: 7px;
    }

    @media(max-width: $screen-md-max) {
      border-bottom: 1px solid $border-grey;

      & > button + .wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        z-index: 1;
        transform: translate(100%, 0);
        transition: all .3s linear;
        display: none;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      &.is-open > button + .wrapper {
        transform: translate(0, 0);
        display: block;

        .back-button {
          display: block;
          width: 100%;

          cx-generic-link a {
            display: flex;
            align-items: center;
            color: $secondary;

            cx-icon {
              transform: scale(0.5);
              margin-right: 12px;
            }
          }
        }
      }

    }
  }


  cx-header-navigation nav ul.childs {
    & > li > cx-generic-link {
      border: none;

      & > a {
        padding: 7px 12px;
        font: 400 14px/30px $primary-font;
        border-radius: 6px;

        &:hover {
          background: #F9E7DD;
        }

        @media(max-width: $screen-md-max) {
          font: 400 17px $primary-font;
          padding: 18px 20px;
          height: auto;
        }
      }
    }

    width: 100%;
  }

  @media(max-width: $screen-md-max) {
    background: $grey-background;
  }

  cx-site-context-selector {
    margin-inline-end: 20px;

    select {
      background-color: transparent;
      color: $dark-grey;
    }

    cx-icon {
      color: $dark-grey;
    }
  }

  .NavigationBar:empty {
    display: none;
  }

  .SiteLinks {
    margin-top: 4px;

    cx-link {
      padding-inline-end: 0;

      a {
        color: $dark-grey;
        font: 400 14px/20px $primary-font;
        text-decoration: none;
        margin-right: 0;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &.is-expanded {

    &::after {
      background-color: $white-background;
    }

    .navigation {
      z-index: 40;
    }

    @media(max-width: $screen-md-max) {
      .header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 24px 24px 24px 16px;
      }

      .SearchBox, .MiniCart {
        display: none;
      }

      .SiteLogo {
        z-index: 4;
      }

      .NavigationBar,
      .SiteLogin,
      .SiteContext {
        background: $white-background;
      }

      .SiteLinks {
        background: transparent;
        padding: 24px 20px;
        margin: 0;

        cx-link {
          a {
            font: 400 17px $primary-font;
            min-height: unset;
          }
        }
      }

      .cx-login-greet {
        display: none;
      }
    }
  }

  .hamburger.is-active:hover, .is-active.cx-hamburger:hover {
    opacity: 1;
  }

  .cx-hamburger {
    margin-right: 12px;

    @media(max-width: $screen-sm-max) {
      margin-right: 0;
    }

    @media(max-width: $screen-xs-max) {
      padding: 8px;

      &.is-active {
        display: flex;
        padding: 0;

        .hamburger-box {
          width: 19px;
          height: 19px;
        }
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: $black;

        &::before,
        &::after {
          background-color: $black;
        }
      }
    }
  }

  .hamburger-inner {
    background-color: $dark-grey;

    &::before,
    &::after {
      background-color: $dark-grey;
    }
  }

  .header {
    @media(max-width: $screen-xs-max) {
      flex-wrap: nowrap;
    }

    @media(max-width: $screen-xxs-max) {
      padding: 0 8px;
    }

    @media (min-width: $screen-lg) {
      padding: 0;
    }
  }

  .SiteLogo {
    width: 165px;
    height: 37px;

    @media(max-width: $screen-sm-max) {
      width: 144px;
      height: 32px;
    }
  }

  cx-login {
    a {
      color: $dark-grey;
    }

    .cx-login-greet {
      color: $dark-grey;
    }
  }

  cx-mini-cart {
    margin: 0;

    a {
      background: transparent;
      color: $dark-grey;
      min-width: 70px;
      padding: 0;

      &:hover {
        color: $primary;
      }

      @media(max-width: $screen-sm-max) {
        flex-direction: row;

        .fa-shopping-cart {
          height: 14px;
        }
      }

      @media(max-width: $screen-xs-max) {
        padding: 0;
      }

      @media(min-width: $screen-md) {
        justify-content: flex-end;
      }
    }

    @media(max-width: $screen-xs-max) {
      margin-inline-start: 0;
    }
  }

  cx-custom-category-navigation, cx-login {
    @extend cx-category-navigation;
  }

  cx-custom-category-navigation {
    cx-header-navigation > nav > ul > li:last-child .wrapper {
      left: unset;
    }
  }

  cx-header-navigation {
    @extend cx-navigation-ui;

    background-color: inherit !important;

    @media(max-width: $screen-md-max) {
      border: none;
    }

    & > nav {
      width: 100%;

      & > ul {
        flex-wrap: nowrap;
        justify-content: space-evenly;

        & > li {
          position: relative;
          display: flex;

          .node-title {
            text-wrap: wrap;
            font: inherit;
            text-transform: inherit;
            white-space: normal;
            display: flex;
          }

          .wrapper {
            left: 0;
            min-width: 100%;
          }

          & > div.wrapper {
            overflow-y: auto;
            overflow-x: hidden;

            span {
              font: 400 14px/20px $primary-font;
              text-transform: capitalize;
              font-weight: normal;
              cursor: pointer;
            }
          }

          & > cx-generic-link > a, & > button {
            .node-header {
              flex-basis: 100%;
              font: 400 12px/14px $primary-font;
            }
          }

          @media(max-width: $screen-md-max) {
            .node-title {
              display: contents;
            }
          }

          @media(min-width: $screen-md) {
            max-width: 10.5em;

            & > cx-generic-link > a, & > button {
              font: 400 14px/20px $primary-font;
              text-transform: capitalize;
              text-align: center;
            }

            .node-title {
              display: inline;
            }

            &:hover {
              cx-generic-link:hover, cx-generic-link.active {
                & > a {
                  color: $primary;
                }

                & ~ .wrapper {
                  @include showWrapperDesktop();
                }
              }

              button:not(.navigate-deeper) {
                color: $primary;

                & > cx-icon {
                  transform: rotateX(180deg);
                }
              }
            }
          }
        }

        @media(min-width: $screen-md) {
          .wrapper {
            font: 400 14px/20px $primary-font;
            text-transform: capitalize;
            top: -10px;
            right: 10px;
            width: max-content;
            max-height: 300px;
            overflow: visible;
          }
        }

        @media(min-width: $screen-md) {
          padding-inline-end: 0;
        }
      }
    }

    &.flyout {
      & > nav > ul > li > button:not(cx-generic-link + button) {
        color: $dark-grey;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        border: none;

        @media(max-width: $screen-md-max) {
          font-family: $primary-font;
          font-size: 17px;
          font-weight: 400;
          padding: 24px 25px 24px 20px;
          text-transform: unset;
        }
      }

      .wrapper-list-item.active {
        cx-generic-link {
          a {
            color: $primary;
          }
        }
      }

      cx-generic-link {
        a {
          cursor: pointer;
          padding: 0;
          white-space: normal;
          text-align: left;
          color: $dark-grey;
          font-size: 16px;
          text-transform: none;

          @media(max-width: $screen-md-max) {
            font: 400 17px $primary-font;
            padding: 24px 0 24px 20px;
          }

          //&.isActive {
          //  font-weight: bold;
          //}
        }
      }

      .wrapper {
        height: auto;
        flex-basis: 100%;

        button {
          display: none;
        }
      }

      &.is-open {
        & > nav > ul > li {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;

          .wrapper {
            background: #FFFFFF;
          }

          & > button {
            display: flex;
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
          }

          & > cx-generic-link {
            display: flex;
            flex-basis: 80%;
            flex-shrink: 0;
            flex-grow: 1;
          }

          &.is-open {
            display: flex;

            & > .wrapper {
              height: fit-content;
            }

            & > button {
              display: flex;
              flex-basis: auto;
              flex-grow: 0;
              flex-shrink: 0;

            }

            & > cx-generic-link {
              display: flex;
              flex-basis: 80%;
              flex-shrink: 0;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  cx-login {
    & > cx-header-navigation > nav > ul > li {
      @media(min-width: $screen-md) {
        max-width: 20vw;
      }
    }
  }

  cx-category-navigation cx-navigation-ui > nav > ul > li,
  cx-category-navigation header cx-header-navigation > nav > ul > li,
  header cx-category-navigation cx-header-navigation > nav > ul > li,
  header cx-custom-category-navigation cx-navigation-ui > nav > ul > li,
  header cx-custom-category-navigation cx-header-navigation > nav > ul > li,
  header cx-login cx-navigation-ui > nav > ul > li,
  header cx-login cx-header-navigation > nav > ul > li {
    padding-inline-start: 0;
  }

  .SiteLogin {
    padding: 0;
  }

}

.navigation {
  .SiteContext {
    padding: 0;
    margin: 0;

    cx-site-context-selector {
      padding: 0;
      margin: 0;
    }
  }
}

.wrapper-list-item {
  cx-generic-link {
    a {
      padding: 0 !important;
      border: none;
      min-height: auto;
    }
  }
}

.hamburger-inner {
  width: 23px;

  &::before, &::after {
    width: 23px;
  }
}

@media(min-width: $screen-md) {
  .wrapper-list-item {
    cx-generic-link {
      a {
        font-size: 14px !important;
      }
    }
  }
}

