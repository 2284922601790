cx-configurator-cart-entry-bundle-info button {
  border: none;
  background-color: transparent;
  outline-offset: 0px;
  padding-inline-start: 0px;
}
cx-configurator-cart-entry-bundle-info button .cx-toggle-hide-items {
  color: var(--cx-color-primary);
  font-size: inherit;
  font-weight: bold;
  inline-size: max-content;
  text-decoration: underline;
  cursor: pointer;
}
cx-configurator-cart-entry-bundle-info .cx-item-infos {
  inline-size: 100%;
  max-block-size: 0;
  overflow: hidden;
  font-size: var(--cx-font-small, 0.8rem);
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}
cx-configurator-cart-entry-bundle-info .cx-item-infos.open {
  max-block-size: fit-content;
  padding-block-end: 15px;
}
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-block-start: 15px;
  padding-block-end: 15px;
  border-bottom: solid 1px var(--cx-color-light);
}
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info .cx-item-name {
  overflow-wrap: break-word;
  width: 100%;
}
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info .cx-item-price,
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info .cx-item-quantity {
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
  width: 100%;
}
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info .cx-item-price .cx-identifier,
cx-configurator-cart-entry-bundle-info .cx-item-infos .cx-item-info .cx-item-quantity .cx-identifier {
  min-width: 5rem;
}

cx-configurator-cart-entry-info .cx-configuration-info {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  cx-configurator-cart-entry-info .cx-configuration-info {
    flex-direction: row;
  }
}
cx-configurator-cart-entry-info .cx-configuration-info:first-of-type {
  margin-block-start: 10px;
}
cx-configurator-cart-entry-info .cx-configuration-info:last-of-type {
  margin-block-end: 10px;
}
cx-configurator-cart-entry-info .cx-configuration-info .cx-label {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  word-break: break-word;
}
cx-configurator-cart-entry-info .cx-configuration-info .cx-value {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  word-break: break-word;
  padding-inline-start: 0px;
}
cx-configurator-cart-entry-info .cx-configuration-info .cx-label,
cx-configurator-cart-entry-info .cx-configuration-info .cx-value {
  width: 100%;
}

cx-configure-product:not(:empty) .btn-block {
  margin-block-start: 10px;
}

@media (min-width: 992px) {
  cx-page-slot.Summary cx-configure-product {
    grid-column: 2;
    grid-row: 4;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 0px;
  }
}

cx-configurator-issues-notification {
  display: none;
  width: 100%;
}
cx-configurator-issues-notification:not(:empty) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(245, 206, 206);
  font-weight: var(--cx-font-weight-semi);
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  margin-block-end: 1.25rem;
}
cx-configurator-issues-notification:not(:empty) cx-icon {
  align-self: flex-start;
  color: var(--cx-color-danger);
  font-size: 30px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
cx-configurator-issues-notification:not(:empty) #cx-error-msg {
  word-break: break-word;
  padding-inline-end: 15px;
}
@media (max-width: 767.98px) {
  cx-configurator-issues-notification:not(:empty) #cx-error-msg {
    padding-inline-end: 85px;
  }
}
cx-configurator-issues-notification:not(:empty) #cx-error-msg .cx-error-msg-action button.link {
  color: var(--cx-color-text);
  text-decoration: underline;
}
cx-configurator-issues-notification:not(:empty) #cx-error-msg .cx-error-msg-action button.link:hover {
  color: var(--cx-color-primary);
  text-decoration: none;
}

cx-configurator-add-to-cart-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--cx-color-background);
  border-top: solid 1px var(--cx-color-light);
  box-shadow: 0 0 5px var(--cx-color-light);
}
cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container,
cx-configurator-add-to-cart-button .cx-display-only-btn-container {
  max-width: 1140px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-block-start: 15px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 16px;
  padding-block-end: 16px;
  margin-top: 0px;
}
@media (max-width: 767.98px) {
  cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container,
  cx-configurator-add-to-cart-button .cx-display-only-btn-container {
    justify-content: center;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-start: 16px;
    padding-block-end: 16px;
  }
}
cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container button.cx-add-to-cart-btn,
cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container button.cx-display-only-btn,
cx-configurator-add-to-cart-button .cx-display-only-btn-container button.cx-add-to-cart-btn,
cx-configurator-add-to-cart-button .cx-display-only-btn-container button.cx-display-only-btn {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  inline-size: 40%;
}
@media (max-width: 767.98px) {
  cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container button.cx-add-to-cart-btn,
  cx-configurator-add-to-cart-button .cx-add-to-cart-btn-container button.cx-display-only-btn,
  cx-configurator-add-to-cart-button .cx-display-only-btn-container button.cx-add-to-cart-btn,
  cx-configurator-add-to-cart-button .cx-display-only-btn-container button.cx-display-only-btn {
    inline-size: 100%;
  }
}

cx-configurator-attribute-input-field {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 12px;
  padding-block-end: 12px;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-input-field {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-block-start: 12px;
    padding-block-end: 12px;
    padding-block-end: 24px;
  }
}
cx-configurator-attribute-input-field em {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-input-field em fieldset {
  width: 100%;
}
cx-configurator-attribute-input-field em fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-input-field em fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-input-field em fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-input-field em fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-input-field em fieldset .form-check .cx-value-price {
    width: 100%;
  }
}

cx-configurator-attribute-checkbox-list {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-checkbox-list fieldset {
  width: 100%;
}
cx-configurator-attribute-checkbox-list fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-checkbox-list fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-checkbox-list fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-checkbox-list fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-checkbox-list fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-checkbox-list .cx-attribute-level-quantity-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
cx-configurator-attribute-checkbox-list .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
  padding-block-end: 10px;
}
cx-configurator-attribute-checkbox-list .cx-attribute-level-quantity-price cx-configurator-price {
  padding-block-end: 10px;
}
cx-configurator-attribute-checkbox-list .cx-quantity {
  padding-block-end: 1rem;
}
cx-configurator-attribute-checkbox-list .form-check input:disabled + .form-check-label {
  color: var(--cx-color-text);
}
cx-configurator-attribute-checkbox-list .form-check input:focus {
  outline-width: var(--cx-visual-focus-width, 2px);
  outline: 2px auto var(--cx-color-visual-focus);
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-offset: 4px;
  transition: none;
}

cx-configurator-attribute-checkbox {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-checkbox fieldset {
  width: 100%;
}
cx-configurator-attribute-checkbox fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-checkbox fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-checkbox fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-checkbox fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-checkbox fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-checkbox .form-check input:focus {
  outline-width: var(--cx-visual-focus-width, 2px);
  outline: 2px auto var(--cx-color-visual-focus);
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-offset: 4px;
  transition: none;
}

cx-configurator-attribute-drop-down {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
  flex-direction: column;
}
cx-configurator-attribute-drop-down fieldset {
  width: 100%;
}
cx-configurator-attribute-drop-down fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-drop-down fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-drop-down fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-drop-down fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-drop-down fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-drop-down .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}
cx-configurator-attribute-drop-down .form-group .cx-required-error-msg {
  border-color: var(--cx-color-danger);
}
@media (min-width: 768px) {
  cx-configurator-attribute-drop-down .form-group select {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-drop-down .form-group {
    flex-direction: column;
  }
  cx-configurator-attribute-drop-down .form-group select {
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-drop-down .form-group .cx-value-price {
    width: 20%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-drop-down .form-group .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-drop-down .cx-attribute-level-quantity-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
cx-configurator-attribute-drop-down .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
  padding-block-end: 10px;
}
cx-configurator-attribute-drop-down .cx-attribute-level-quantity-price cx-configurator-price {
  padding-block-end: 10px;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-drop-down .cx-value-price {
    margin-block-start: 32px;
  }
}
cx-configurator-attribute-drop-down .cx-attribute-level-quantity-price {
  margin-block-start: 32px;
}
cx-configurator-attribute-drop-down .cx-configurator-attribute-additional-value {
  margin-inline-start: 0px;
}

cx-configurator-attribute-footer {
  display: flex;
  flex-direction: row;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-footer .cx-required-error-msg {
  color: var(--cx-color-danger);
  font-size: 14px;
}
cx-configurator-attribute-footer .cx-required-error-msg cx-icon {
  padding-inline-end: 5px;
}

cx-configurator-attribute-header {
  display: flex;
  flex-direction: column;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
  margin-block-end: 0px;
}
cx-configurator-attribute-header label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-block-start: 10px;
}
cx-configurator-attribute-header .cx-required-icon:after {
  content: "*";
  color: var(--cx-color-danger);
}
cx-configurator-attribute-header .cx-required-error-msg {
  color: var(--cx-color-danger);
  font-size: 14px;
}
cx-configurator-attribute-header .cx-required-error-msg cx-icon {
  padding-inline-end: 5px;
}
cx-configurator-attribute-header .cx-conflict-msg,
cx-configurator-attribute-header .cx-hidden-msg {
  display: inline-flex;
}
cx-configurator-attribute-header .cx-conflict-msg cx-icon,
cx-configurator-attribute-header .cx-hidden-msg cx-icon {
  color: var(--cx-color-warning);
  font-size: 20px;
  padding-inline-end: 5px;
}
cx-configurator-attribute-header .cx-conflict-msg .cx-action-link {
  font-size: 14px;
  min-height: 0px;
}
cx-configurator-attribute-header a.cx-conflict-msg {
  cursor: pointer;
}
cx-configurator-attribute-header a.cx-action-link {
  cursor: pointer;
}
cx-configurator-attribute-header img {
  width: 25%;
  padding-block-end: 5px;
  padding-block-start: 5px;
  display: block;
}

cx-configurator-attribute-input-field {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-input-field fieldset {
  width: 100%;
}
cx-configurator-attribute-input-field fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-input-field fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-input-field fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-input-field fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-input-field fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-input-field .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}
cx-configurator-attribute-input-field .form-group .cx-required-error-msg {
  border-color: var(--cx-color-danger);
}
@media (min-width: 768px) {
  cx-configurator-attribute-input-field .form-group select {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-input-field .form-group {
    flex-direction: column;
  }
  cx-configurator-attribute-input-field .form-group select {
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-input-field .form-group .cx-value-price {
    width: 20%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-input-field .form-group .cx-value-price {
    width: 100%;
  }
}

cx-configurator-attribute-multi-selection-bundle .cx-attribute-level-quantity-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 32px;
  margin-block-end: 32px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}
cx-configurator-attribute-multi-selection-bundle .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
  padding-block-end: 10px;
}
cx-configurator-attribute-multi-selection-bundle .cx-attribute-level-quantity-price cx-configurator-price {
  padding-block-end: 10px;
}

cx-configurator-attribute-multi-selection-image {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-multi-selection-image fieldset {
  width: 100%;
}
cx-configurator-attribute-multi-selection-image fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-multi-selection-image fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-multi-selection-image fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-multi-selection-image fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-multi-selection-image fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-multi-selection-image .cx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select {
  width: 200px;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input,
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=radio].form-input {
  opacity: 0;
  position: absolute;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input:focus:not(:focus-visible) + .cx-label-container,
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=radio].form-input:focus:not(:focus-visible) + .cx-label-container {
  outline: none;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input:focus + .cx-label-container,
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[type=radio].form-input:focus + .cx-label-container {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[checked=checked] + .cx-label-container .cx-img,
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select input[checked=checked] + .cx-label-container .cx-img-dummy {
  border: var(--cx-color-primary) 3px solid;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container label {
  cursor: pointer;
  text-align: center;
  line-break: anywhere;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img,
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img-dummy {
  display: block;
  border-radius: 2px;
  border: transparent 3px solid;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  padding-block-start: 3px;
  padding-block-end: 3px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img-dummy {
  block-size: 75%;
}
cx-configurator-attribute-multi-selection-image .cx-row .cx-configurator-select .cx-label-container cx-configurator-price {
  align-items: center;
}

cx-configurator-attribute-numeric-input-field {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
  flex-direction: column;
}
cx-configurator-attribute-numeric-input-field fieldset {
  width: 100%;
}
cx-configurator-attribute-numeric-input-field fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-numeric-input-field fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-numeric-input-field fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-numeric-input-field fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-numeric-input-field fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-numeric-input-field .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}
cx-configurator-attribute-numeric-input-field .form-group .cx-required-error-msg {
  border-color: var(--cx-color-danger);
}
@media (min-width: 768px) {
  cx-configurator-attribute-numeric-input-field .form-group select {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-numeric-input-field .form-group {
    flex-direction: column;
  }
  cx-configurator-attribute-numeric-input-field .form-group select {
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-numeric-input-field .form-group .cx-value-price {
    width: 20%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-numeric-input-field .form-group .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-numeric-input-field .cx-validation-msg {
  color: var(--cx-color-danger);
  font-size: 14px;
}
cx-configurator-attribute-numeric-input-field .cx-validation-msg cx-icon {
  padding-inline-end: 5px;
}

cx-configurator-attribute-product-card:first-of-type .cx-product-card {
  border-top: solid 1px var(--cx-color-light);
}
cx-configurator-attribute-product-card .cx-product-card {
  padding-block-start: 16px;
  padding-block-end: 16px;
  width: 100%;
  min-height: 140px;
  border-bottom: solid 1px var(--cx-color-light);
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows {
    width: 100%;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows.column {
  flex-direction: column;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-block-end: 16px;
  min-height: 48px;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity,
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-price {
  width: 50%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity:empty {
    width: 0%;
  }
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity:empty + .cx-product-card-price {
    width: 100%;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-price {
  height: fit-content;
  width: fit-content;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity {
  width: fit-content;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-quantity-price .cx-product-card-quantity {
    margin-inline-end: 4rem;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-action {
  display: flex;
  flex-direction: row;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows .cx-product-card-action {
    align-self: flex-end;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-rows.deselection-error-message {
  display: inline-block;
  width: 80%;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-imgs {
  width: 3rem;
  height: 3rem;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-imgs {
    height: 5rem;
    min-width: 5rem;
    width: 5rem;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-imgs cx-media {
  background-color: var(--cx-color-light);
  height: 100%;
  width: 100%;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-imgs img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-inline-start: 16px;
  width: 15.5rem;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info {
    width: 18rem;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info .cx-product-card-name a,
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info .cx-product-card-name p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info .cx-product-card-name p {
  color: var(--cx-color-primary);
  margin-block-end: 0;
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-info .cx-product-card-code {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
  color: var(--cx-color-secondary);
  font-size: 0.875rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-action-btn {
    min-width: 6rem;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-action-btn {
    width: 100%;
  }
}
cx-configurator-attribute-product-card .cx-product-card .cx-product-card-action-btn .btn {
  font-size: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  cx-configurator-attribute-product-card .cx-product-card .cx-product-card-action-btn .btn {
    max-width: 20rem;
  }
}
cx-configurator-attribute-product-card .cx-product-card-selected {
  flex-wrap: wrap;
  background-color: var(--cx-color-background);
}
cx-configurator-attribute-product-card .cx-product-card-selected cx-item-counter {
  background-color: #fff;
}
cx-configurator-attribute-product-card .deselection-error-message {
  padding-top: 5px;
  color: var(--cx-color-danger);
}
cx-configurator-attribute-product-card .deselection-error-symbol {
  padding: 5px;
}

cx-configurator-attribute-quantity {
  display: flex;
  flex-direction: column;
}
cx-configurator-attribute-quantity .cx-quantity {
  display: flex;
  align-items: center;
}
cx-configurator-attribute-quantity .cx-quantity label {
  margin-inline-end: 0.5rem;
}

cx-configurator-price {
  display: none;
}
cx-configurator-price:not(:empty) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
cx-configurator-price:not(:empty) .cx-quantity-price {
  font-size: var(--cx-font-small, 0.9rem);
  line-break: anywhere;
}
cx-configurator-price:not(:empty) .cx-price,
cx-configurator-price:not(:empty) .cx-price-total {
  font-weight: bold;
  line-break: anywhere;
}
cx-configurator-price:not(:empty) .cx-greyed-out {
  color: var(--cx-color-secondary);
}

cx-configurator-attribute-radio-button {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-radio-button fieldset {
  width: 100%;
}
cx-configurator-attribute-radio-button fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-radio-button fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-radio-button fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-radio-button fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-radio-button fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-radio-button .cx-attribute-level-quantity-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
cx-configurator-attribute-radio-button .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
  padding-block-end: 10px;
}
cx-configurator-attribute-radio-button .cx-attribute-level-quantity-price cx-configurator-price {
  padding-block-end: 10px;
}
cx-configurator-attribute-radio-button .form-check:last-child {
  margin-block-end: 9px;
}
cx-configurator-attribute-radio-button .form-check input:focus {
  outline-width: var(--cx-visual-focus-width, 2px);
  outline: 2px auto var(--cx-color-visual-focus);
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-offset: 4px;
  transition: none;
}
cx-configurator-attribute-radio-button .cx-quantity {
  padding-block-end: 1rem;
}
cx-configurator-attribute-radio-button .cx-configurator-attribute-additional-value {
  margin-inline-start: 0px;
}

cx-configurator-attribute-read-only {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-read-only fieldset {
  width: 100%;
}
cx-configurator-attribute-read-only fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-read-only fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-read-only fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-read-only fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-read-only fieldset .form-check .cx-value-price {
    width: 100%;
  }
}

cx-configurator-attribute-single-selection-bundle-dropdown {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
  flex-direction: column;
}
cx-configurator-attribute-single-selection-bundle-dropdown fieldset {
  width: 100%;
}
cx-configurator-attribute-single-selection-bundle-dropdown fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-single-selection-bundle-dropdown fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-single-selection-bundle-dropdown fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-bundle-dropdown fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-single-selection-bundle-dropdown fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-single-selection-bundle-dropdown .form-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}
cx-configurator-attribute-single-selection-bundle-dropdown .form-group .cx-required-error-msg {
  border-color: var(--cx-color-danger);
}
@media (min-width: 768px) {
  cx-configurator-attribute-single-selection-bundle-dropdown .form-group select {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-bundle-dropdown .form-group {
    flex-direction: column;
  }
  cx-configurator-attribute-single-selection-bundle-dropdown .form-group select {
    width: 100%;
  }
}
@media (min-width: 768px) {
  cx-configurator-attribute-single-selection-bundle-dropdown .form-group .cx-value-price {
    width: 20%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-bundle-dropdown .form-group .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-single-selection-bundle-dropdown cx-configurator-attribute-product-card:first-of-type .cx-product-card-rows {
  padding-inline-start: 0px;
}
cx-configurator-attribute-single-selection-bundle-dropdown cx-configurator-attribute-product-card .cx-product-card-rows.column {
  padding-inline-end: 0px;
}
cx-configurator-attribute-single-selection-bundle-dropdown cx-configurator-attribute-product-card:first-of-type .cx-product-card {
  border-top: none;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-bundle-dropdown cx-configurator-attribute-product-card {
    margin-inline-start: -17px;
    margin-inline-end: -17px;
  }
}
cx-configurator-attribute-single-selection-bundle-dropdown .cx-attribute-level-quantity-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 32px;
  margin-block-end: 32px;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-bundle-dropdown .cx-attribute-level-quantity-price {
    margin-inline-end: 0px;
  }
}
cx-configurator-attribute-single-selection-bundle-dropdown .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
}

cx-configurator-attribute-single-selection-bundle .cx-attribute-level-quantity-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 32px;
  margin-block-end: 32px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}
cx-configurator-attribute-single-selection-bundle .cx-attribute-level-quantity-price cx-configurator-attribute-quantity {
  padding-inline-end: 10px;
  padding-block-end: 10px;
}
cx-configurator-attribute-single-selection-bundle .cx-attribute-level-quantity-price cx-configurator-price {
  padding-block-end: 10px;
}

cx-configurator-attribute-single-selection-image {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-attribute-single-selection-image fieldset {
  width: 100%;
}
cx-configurator-attribute-single-selection-image fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-attribute-single-selection-image fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-attribute-single-selection-image fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-attribute-single-selection-image fieldset .form-check .cx-value-label-pair,
  cx-configurator-attribute-single-selection-image fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-attribute-single-selection-image .cx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select {
  width: 200px;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input,
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=radio].form-input {
  opacity: 0;
  position: absolute;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input:focus:not(:focus-visible) + .cx-label-container,
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=radio].form-input:focus:not(:focus-visible) + .cx-label-container {
  outline: none;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=checkbox].form-input:focus + .cx-label-container,
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[type=radio].form-input:focus + .cx-label-container {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[checked=checked] + .cx-label-container .cx-img,
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select input[checked=checked] + .cx-label-container .cx-img-dummy {
  border: var(--cx-color-primary) 3px solid;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container label {
  cursor: pointer;
  text-align: center;
  line-break: anywhere;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img,
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img-dummy {
  display: block;
  border-radius: 2px;
  border: transparent 3px solid;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
  padding-block-start: 3px;
  padding-block-end: 3px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container label .cx-img-dummy {
  block-size: 75%;
}
cx-configurator-attribute-single-selection-image .cx-row .cx-configurator-select .cx-label-container cx-configurator-price {
  align-items: center;
}

cx-configurator-conflict-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  background-color: #fff1c8;
}
cx-configurator-conflict-description cx-icon {
  color: #ffc107;
  align-self: center;
  font-size: 30px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 5px;
  padding-block-end: 15px;
}

cx-configurator-conflict-suggestion {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fefefe;
  border: 1px solid var(--cx-color-light);
  border-radius: 2px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 15px;
  padding-block-end: 15px;
  margin-inline-start: -15px;
  margin-inline-end: -15px;
  margin-block-start: 0px;
  margin-block-end: 15px;
}
@media (max-width: 767.98px) {
  cx-configurator-conflict-suggestion {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
    padding-block-start: 15px;
    padding-block-end: 15px;
  }
}
cx-configurator-conflict-suggestion .cx-title {
  font-weight: bold;
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  padding-inline-start: 0px;
}

cx-configurator-cpq-overview-attribute {
  display: none;
}
cx-configurator-cpq-overview-attribute:not(:empty) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-block-start: 15px;
  padding-block-end: 15px;
}
@media (max-width: 767.98px) {
  cx-configurator-cpq-overview-attribute:not(:empty) {
    flex-direction: column;
  }
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container {
  display: flex;
  flex-direction: row;
  width: 40%;
}
@media (max-width: 767.98px) {
  cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container {
    width: 100%;
  }
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-thumbnail {
  width: 3rem;
  height: 3rem;
}
@media (min-width: 768px) {
  cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-thumbnail {
    height: 5rem;
    min-width: 5rem;
    width: 5rem;
  }
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-thumbnail cx-media {
  background-color: var(--cx-color-light);
  height: 100%;
  width: 100%;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-thumbnail img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  width: 80%;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-code {
  display: block;
  color: var(--cx-color-secondary);
  font-size: 0.875rem;
  overflow-wrap: anywhere;
  padding-block-end: 5px;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-quantity,
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-price {
  display: flex;
  flex-direction: row;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-quantity .cx-identifier,
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-price .cx-identifier {
  width: 35%;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-quantity .cx-item,
cx-configurator-cpq-overview-attribute:not(:empty) .cx-value-container .cx-value-info .cx-price .cx-item {
  width: fit-content;
  line-break: anywhere;
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-attribute-price-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--cx-color-secondary);
  width: 60%;
}
@media (max-width: 767.98px) {
  cx-configurator-cpq-overview-attribute:not(:empty) .cx-attribute-price-container {
    width: 100%;
    align-items: flex-start;
    padding-block-start: 5px;
  }
}
cx-configurator-cpq-overview-attribute:not(:empty) .cx-attribute-price-container .cx-attribute-label {
  padding-inline-end: 10px;
}

cx-configurator-group {
  width: 100%;
}
cx-configurator-group .cx-group-attribute {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 12px;
  padding-block-end: 12px;
}
@media (max-width: 767.98px) {
  cx-configurator-group .cx-group-attribute {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-block-start: 12px;
    padding-block-end: 12px;
    padding-block-end: 24px;
  }
}
cx-configurator-group .cx-group-attribute em {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-group .cx-group-attribute em fieldset {
  width: 100%;
}
cx-configurator-group .cx-group-attribute em fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-group .cx-group-attribute em fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-group .cx-group-attribute em fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-group .cx-group-attribute em fieldset .form-check .cx-value-label-pair,
  cx-configurator-group .cx-group-attribute em fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
cx-configurator-group .cx-hidden {
  background-color: var(--cx-color-background);
}
cx-configurator-group cx-item-counter.readonly input {
  border: solid 1px var(--cx-color-light);
  min-height: 48px;
  width: 10rem;
}

cx-configurator-form, cx-configurator-conflict-solver-dialog:not(:empty) {
  width: 100%;
}
cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 12px;
  padding-block-end: 12px;
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-block-start: 12px;
    padding-block-end: 12px;
    padding-block-end: 24px;
  }
}
cx-configurator-form .cx-ghost-attribute em, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-form .cx-ghost-attribute em fieldset, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset {
  width: 100%;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair,
  cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-price,
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-block-start: 12px;
    padding-block-end: 12px;
  }
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-title,
cx-configurator-form .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description,
cx-configurator-form .cx-ghost-attribute .cx-ghost-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-price,
cx-configurator-form .cx-ghost-attribute .cx-ghost-label,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-label {
  height: 30px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-title,
cx-configurator-form .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description,
cx-configurator-form .cx-ghost-attribute .cx-ghost-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-price,
cx-configurator-form .cx-ghost-attribute .cx-ghost-label,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-label,
cx-configurator-form .cx-ghost-attribute .cx-ghost-text,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-text,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-price {
  background-color: var(--cx-color-ghost);
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description-box,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description-box,
cx-configurator-form .cx-ghost-attribute .cx-ghost-body,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body,
cx-configurator-form .cx-ghost-attribute .cx-ghost-radiobutton-value,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-radiobutton-value,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-label-pair,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-label-pair,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-price {
  display: flex;
  flex-direction: row;
  align-items: center;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-block-end: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-title {
  width: 30%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-icon, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-icon {
  width: 30px;
  border-radius: 50%;
  margin-inline-start: 10px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box {
  flex: 0 0 100%;
  margin-block-start: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box .cx-ghost-description, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box .cx-ghost-description {
  width: 60%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-block-end: 15px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-text, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-text {
  width: 70%;
  height: 72px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-price {
  width: 10%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value {
  flex: 0 0 100%;
  justify-content: space-between;
  margin-block-start: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair {
  width: 80%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-value-icon, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-value-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-label {
  margin-inline-start: 10px;
  height: 30px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(1) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(1) .cx-ghost-label {
  width: 20%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(2) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(2) .cx-ghost-label {
  width: 55%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(3) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(3) .cx-ghost-label {
  width: 30%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-price {
  width: 10%;
  align-items: flex-end;
}

cx-configurator-group-menu:not(:empty) .cx-group-menu,
cx-configurator-group-menu:not(:empty) .cx-ghost-group-menu {
  margin-inline-end: 25px;
}
@media (max-width: 991.98px) {
  cx-configurator-group-menu:not(:empty) .cx-group-menu,
  cx-configurator-group-menu:not(:empty) .cx-ghost-group-menu {
    background-color: var(--cx-color-background);
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
}
cx-configurator-group-menu:not(:empty) .cx-group-menu {
  list-style-type: none;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
  border-radius: 2px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 0px;
  padding-block-end: 0px;
  margin-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--cx-color-text);
  border: none;
  background-color: transparent;
  outline-offset: 0px;
  width: 100%;
  line-height: var(--cx-line-height, 1.6);
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 15px;
  padding-block-end: 15px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item:hover {
  cursor: pointer;
  color: var(--cx-color-primary);
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.active {
  color: var(--cx-color-primary);
  font-weight: bold;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.disable {
  cursor: not-allowed;
  pointer-events: none;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators {
  display: flex;
  flex-direction: row;
  align-self: center;
  inline-size: 100px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator {
  inline-size: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.ERROR,
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.WARNING,
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.COMPLETE {
  display: none;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.ERROR {
  color: var(--cx-color-danger);
  padding-inline-end: 5px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.WARNING {
  color: var(--cx-color-warning);
  padding-inline-end: 5px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .groupStatusIndicator cx-icon.COMPLETE {
  color: var(--cx-color-text);
  padding-inline-end: 5px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .subGroupIndicator {
  inline-size: 25px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .subGroupIndicator cx-icon {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item .groupIndicators .conflictNumberIndicator {
  inline-size: 25px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.ERROR cx-icon.ERROR, cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.WARNING cx-icon.WARNING, cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.COMPLETE cx-icon.COMPLETE {
  display: inline-block !important;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item.cx-menu-conflict {
  background-color: #fff1c8;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu:last-child {
  border-width: 0 0 1px 0;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-back {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--cx-color-text);
  border: none;
  background-color: transparent;
  outline-offset: 0px;
  width: 100%;
  line-height: var(--cx-line-height, 1.6);
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
  background-color: var(--cx-color-background);
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 15px;
  padding-block-end: 15px;
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-back:hover {
  cursor: pointer;
  color: var(--cx-color-primary);
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-back.active {
  color: var(--cx-color-primary);
}
cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-back cx-icon {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}
@media (max-width: 991.98px) {
  cx-configurator-group-menu:not(:empty) .cx-group-menu {
    background-color: var(--cx-color-background);
  }
  cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-back {
    font-weight: var(--cx-font-weight-semi);
    border-bottom: 1px solid var(--cx-color-light);
    background-color: var(--cx-color-medium);
  }
  cx-configurator-group-menu:not(:empty) .cx-group-menu .cx-menu-item {
    text-transform: uppercase;
    font-weight: var(--cx-font-weight-semi);
  }
  cx-configurator-group-menu:not(:empty) .cx-group-menu button:not(.cx-menu-conflict) {
    border-bottom: 1px solid var(--cx-color-medium);
  }
}
cx-configurator-group-menu:not(:empty) .cx-ghost-group-menu .cx-ghost-menu-item {
  padding-block-end: 5px;
}
cx-configurator-group-menu:not(:empty) .cx-ghost-group-menu .cx-ghost-menu-item .cx-ghost-item-title {
  height: 55px;
  background-color: var(--cx-color-ghost);
}

cx-configurator-group-title:not(:empty) .cx-group-title,
cx-configurator-group-title:not(:empty) .cx-ghost-group-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 15px;
  padding-block-end: 15px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
cx-configurator-group-title:not(:empty) .cx-group-title {
  font-weight: bold;
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-light);
  border-radius: 2px;
}
cx-configurator-group-title:not(:empty) .cx-group-title cx-hamburger-menu button {
  padding-block-start: 0px;
  padding-block-end: 0px;
}
@media (max-width: 767.98px) {
  cx-configurator-group-title:not(:empty) .cx-group-title cx-hamburger-menu button {
    padding-inline-start: 0px;
  }
}
cx-configurator-group-title:not(:empty) .cx-group-title cx-hamburger-menu button .hamburger-inner, cx-configurator-group-title:not(:empty) .cx-group-title cx-hamburger-menu button .hamburger-inner::before, cx-configurator-group-title:not(:empty) .cx-group-title cx-hamburger-menu button .hamburger-inner::after {
  background-color: var(--cx-color-primary);
}
cx-configurator-group-title:not(:empty) .cx-ghost-group-title {
  height: 55px;
  background-color: var(--cx-color-ghost);
}

cx-configurator-overview-attribute {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
cx-configurator-overview-attribute .cx-attribute-value {
  width: 50%;
  font-weight: 600;
  line-break: normal;
  padding-inline-end: 10px;
  z-index: -6;
}
@media (min-width: 768px) {
  cx-configurator-overview-attribute .cx-attribute-value {
    width: 40%;
  }
}
cx-configurator-overview-attribute .cx-attribute-label {
  width: 100%;
  padding-inline-end: 10px;
  line-break: normal;
  color: var(--cx-color-secondary);
  z-index: -6;
}
@media (min-width: 768px) {
  cx-configurator-overview-attribute .cx-attribute-label {
    width: 30%;
  }
}
cx-configurator-overview-attribute .cx-attribute-price {
  align-self: flex-start;
  color: var(--cx-color-secondary);
  width: 50%;
  z-index: -6;
}
@media (min-width: 768px) {
  cx-configurator-overview-attribute .cx-attribute-price {
    width: 30%;
    padding-block-start: 5px;
  }
}

cx-configurator-overview-filter:not(:empty) {
  padding-inline-end: 30px;
}
cx-configurator-overview-filter .cx-overview-filter-header,
cx-configurator-overview-filter .cx-overview-filter-option {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  margin-block-start: 15px;
  margin-block-end: 15px;
}
cx-configurator-overview-filter .cx-overview-filter-header {
  font-weight: bold;
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-light);
  border-radius: 2px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 15px;
  padding-block-end: 15px;
  margin-block-start: 25px;
}

cx-configurator-overview-filter-bar:not(:empty) {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
cx-configurator-overview-filter-bar .cx-overview-filter-applied {
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-light);
  border-radius: 2px;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  margin-inline-start: 0px;
  margin-inline-end: 10px;
  margin-block-start: 10px;
  margin-block-end: 5px;
  width: fit-content;
}
cx-configurator-overview-filter-bar .cx-overview-filter-applied .cx-icon {
  padding-inline-start: 10px;
}

cx-configurator-overview-filter-button:not(:empty) {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-block-start: 20px;
}
cx-configurator-overview-filter-button .cx-config-filter-button {
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}
@media (max-width: 991.98px) {
  cx-configurator-overview-filter-button .cx-config-filter-button {
    inline-size: 100%;
  }
}
cx-configurator-overview-filter-button cx-configurator-overview-filter-bar {
  margin-inline-start: 16px;
}
cx-configurator-overview-filter-button .cx-ghost-filter-button {
  width: 100px;
  height: 50px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
  background-color: var(--cx-color-ghost);
  margin-block-end: 16px;
  top: -8px;
}
@media (max-width: 991.98px) {
  cx-configurator-overview-filter-button .cx-ghost-filter-button {
    inline-size: 100%;
  }
}

cx-configurator-overview-filter-dialog:not(:empty) {
  z-index: 1000;
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-modal-content {
  margin: auto;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-filter-dialog:not(:empty) .cx-modal-content {
    min-height: 100%;
  }
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-header {
  padding-top: 2rem;
  padding-inline-end: 1.75rem;
  padding-bottom: 1.25rem;
  padding-inline-start: 1.75rem;
  display: flex;
  top: 0px;
  position: sticky;
  background-color: var(--cx-color-inverse);
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-header .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  align-self: flex-start;
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-header.modal-header .close {
  padding-bottom: 0rem;
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-body {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
cx-configurator-overview-filter-dialog:not(:empty) .cx-dialog-body cx-configurator-overview-filter {
  padding: 0px;
}

cx-configurator-overview-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1140px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
}
cx-configurator-overview-form .cx-group {
  padding-block-start: 25px;
  padding-block-end: 25px;
  margin-inline-start: -20px;
  margin-inline-end: -25px;
}
@media (min-width: 768px) {
  cx-configurator-overview-form .cx-group {
    padding-block-start: 20px;
    padding-block-end: 20px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group {
    padding-block-start: 20px;
    padding-block-end: 20px;
    margin-inline-start: -20px;
    margin-inline-end: -20px;
  }
}
cx-configurator-overview-form .cx-group.topLevel h2 {
  font-size: 1.25rem;
  font-weight: 700;
  border-bottom: solid 1px var(--cx-color-light);
  border-top: solid 1px var(--cx-color-light);
  border-left-style: none;
  border-right-style: none;
  text-transform: none;
  background: var(--cx-color-inverse) none;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group.topLevel h2 {
    position: sticky;
    top: 2px;
    z-index: 5;
  }
}
cx-configurator-overview-form .cx-group.subgroupTopLevel {
  margin-bottom: -20px;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group.subgroup {
    padding-block-start: 20px;
    padding-block-end: 20px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}
cx-configurator-overview-form .cx-group.subgroup h2 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  border: solid 1px var(--cx-color-light);
  background-color: var(--cx-color-background);
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group.subgroup h2 {
    position: sticky;
  }
}
cx-configurator-overview-form .cx-group h2 {
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  padding-block-start: 16px;
  padding-block-end: 16px;
  border: solid 1px var(--cx-color-light);
  background-color: var(--cx-color-background);
  font-size: 1rem;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group h2 {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-start: 16px;
    padding-block-end: 16px;
  }
}
cx-configurator-overview-form .cx-group h2 span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}
cx-configurator-overview-form .cx-group .cx-attribute-value-pair {
  padding-inline-start: 32px;
  padding-inline-end: 32px;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
@media (min-width: 768px) {
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair.general:not(:first-of-type) .cx-attribute-label {
    visibility: hidden;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair.general .cx-attribute-label {
    display: none;
  }
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair.general.last-value-pair .cx-attribute-label {
    display: inline;
  }
}
cx-configurator-overview-form .cx-group .cx-attribute-value-pair.bundle {
  background-color: var(--cx-color-background);
}
@media (min-width: 768px) {
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair.bundle:not(:first-of-type) .cx-attribute-label {
    visibility: hidden;
  }
}
cx-configurator-overview-form .cx-group .cx-attribute-value-pair.margin {
  margin-block-start: 15px;
}
cx-configurator-overview-form .cx-group .cx-attribute-value-pair.margin.bundle .cx-attribute-label {
  visibility: visible;
}
@media (min-width: 768px) {
  cx-configurator-overview-form .cx-group .cx-attribute-value-pair.margin.general .cx-attribute-label {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-group.subgroupLevel2 h2 {
    top: 60px;
    z-index: 4;
  }
  cx-configurator-overview-form .cx-group.subgroupLevel3 h2 {
    top: 112px;
    z-index: 3;
  }
  cx-configurator-overview-form .cx-group.subgroupLevel4 h2 {
    top: 164px;
    z-index: 2;
  }
  cx-configurator-overview-form .cx-group.subgroupLevel5 h2 {
    top: 216px;
    z-index: 1;
  }
}
cx-configurator-overview-form .cx-no-attribute-value-pairs {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-block-start: 20px;
  padding-block-end: 20px;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-form .cx-ghost-group {
    margin-inline-start: -20px;
    margin-inline-end: -20px;
  }
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-header,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-value,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-label,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-attribute-price {
  background-color: var(--cx-color-ghost);
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-value,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-label,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-attribute-price {
  height: 30px;
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-header {
  width: 100%;
  height: 58px;
  margin-block-end: 8px;
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-block-start: 15px;
}
@media (max-width: 991.98px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value,
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-price {
  margin-block-start: 10px;
  margin-block-end: 10px;
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value {
  width: 30%;
}
@media (max-width: 575.98px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value {
    width: 30%;
  }
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value .ghost {
  height: 25px;
}
@media (min-width: 576px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(1) .ghost {
    width: 25%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(4) .ghost {
    width: 75%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(7) .ghost {
    width: 25%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(10) .ghost {
    width: 65%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(13) .ghost {
    width: 25%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-value:nth-child(16) .ghost {
    width: 35%;
  }
}
cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label {
  width: 60%;
}
@media (max-width: 575.98px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label {
    width: 80%;
  }
}
@media (min-width: 576px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(2) .ghost {
    width: 65%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(5) .ghost {
    width: 80%;
    height: 85px;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(8) .ghost {
    width: 75%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(11) .ghost {
    width: 70%;
    height: 65px;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(14) .ghost {
    width: 60%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-label:nth-child(17) .ghost {
    width: 70%;
  }
}
@media (min-width: 576px) {
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-price {
    width: 10%;
  }
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-price:nth-child(6),
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-price:nth-child(9),
  cx-configurator-overview-form .cx-ghost-group .cx-ghost-body .cx-ghost-attribute-price:nth-child(18) {
    background-color: transparent;
  }
}

cx-configurator-overview-menu:not(:empty) {
  overflow-y: auto;
}
cx-configurator-overview-menu:not(:empty) ul {
  list-style-type: none;
  padding-inline-start: 0px;
  padding-block-start: 5px;
  padding-block-end: 0px;
  margin-block-end: 0px;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group {
  padding-block-start: 10px;
  margin-inline-start: 20px;
  list-style-type: none;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: var(--cx-color-text);
  border: none;
  background-color: transparent;
  transition: all 50ms ease-in-out 50ms; /*This small transition makes setting of the active state smooth */
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item span {
  margin-inline-end: 5px;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item cx-icon {
  visibility: hidden;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item.active, cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item:hover {
  color: var(--cx-color-primary);
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item.active cx-icon {
  visibility: visible;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group .cx-menu-item span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}
cx-configurator-overview-menu:not(:empty) ul .cx-menu-group.groupLevel1 {
  margin-inline-start: 0px;
}

cx-configurator-overview-notification-banner {
  display: none;
}
cx-configurator-overview-notification-banner:not(:empty) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1140px;
}
cx-configurator-overview-notification-banner:not(:empty) .cx-error-notification-banner {
  background-color: rgb(245, 206, 206);
}
cx-configurator-overview-notification-banner:not(:empty) .cx-error-notification-banner .cx-icon {
  color: var(--cx-color-danger);
}
cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-notification-banner {
  background-color: #fff1c8;
}
cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-notification-banner .cx-icon {
  color: var(--cx-color-warning);
}
cx-configurator-overview-notification-banner:not(:empty) .cx-error-notification-banner,
cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-notification-banner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-block-end: 1.25rem;
  padding-inline-start: 20px;
  padding-inline-end: 25px;
  padding-block-start: 5px;
  padding-block-end: 5px;
  align-items: center;
}
@media (max-width: 767.98px) {
  cx-configurator-overview-notification-banner:not(:empty) .cx-error-notification-banner,
  cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-notification-banner {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
}
cx-configurator-overview-notification-banner:not(:empty) cx-icon,
cx-configurator-overview-notification-banner:not(:empty) .cx-icon {
  align-self: flex-start;
  font-size: 30px;
  padding-inline-start: 5px;
  padding-inline-end: 15px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
cx-configurator-overview-notification-banner:not(:empty) .cx-error-msg,
cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-msg {
  padding-inline-end: 15px;
}
cx-configurator-overview-notification-banner:not(:empty) .cx-error-msg button.link,
cx-configurator-overview-notification-banner:not(:empty) .cx-conflict-msg button.link {
  font-size: inherit;
}

cx-configurator-previous-next-buttons {
  display: none;
}
cx-configurator-previous-next-buttons:not(:empty) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 16px;
  padding-block-end: 16px;
}
@media (max-width: 767.98px) {
  cx-configurator-previous-next-buttons:not(:empty) {
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
cx-configurator-previous-next-buttons:not(:empty) .btn-secondary {
  inline-size: 25%;
}
@media (max-width: 767.98px) {
  cx-configurator-previous-next-buttons:not(:empty) .btn-secondary {
    inline-size: 45%;
  }
}

cx-configurator-price-summary:not(:empty) .cx-price-summary-container {
  max-width: 1140px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-block-start: 15px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 16px;
  padding-block-end: 16px;
}
@media (max-width: 767.98px) {
  cx-configurator-price-summary:not(:empty) .cx-price-summary-container {
    justify-content: center;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-start: 16px;
    padding-block-end: 16px;
  }
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  inline-size: 40%;
}
@media (max-width: 767.98px) {
  cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary {
    inline-size: 100%;
  }
}
@media (min-width: 768px) {
  cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary .cx-summary-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-block-start: 10px;
  padding-block-end: 10px;
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary .cx-summary-row .cx-label {
  flex-grow: 1;
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary .cx-summary-row .cx-amount {
  word-wrap: break-word;
  text-align: end;
  flex-grow: 1;
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary .cx-total-price {
  border-block-start: 1px solid var(--cx-color-light);
}
cx-configurator-price-summary:not(:empty) .cx-price-summary-container .cx-total-summary .cx-total-price .cx-label {
  font-weight: bold;
}

@media (min-width: 992px) {
  cx-configurator-product-title:not(:empty) {
    width: 1140px;
  }
}
@media (max-width: 1199.98px) {
  cx-configurator-product-title:not(:empty) {
    width: 100%;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info,
cx-configurator-product-title:not(:empty) .cx-ghost-general-product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--cx-color-background);
  margin-block-end: 15px;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 15px;
  padding-block-end: 15px;
  box-shadow: inset 0px 10px 7px -12px var(--cx-color-dark);
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-title {
  font-weight: bold;
  align-self: center;
  min-block-size: 1rem;
}
@media (max-width: 767.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-title {
    width: 80%;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-title span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
  -webkit-line-clamp: unset;
  line-break: auto;
  text-align: center;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: flex-end;
  cursor: pointer;
  margin-block-start: -25px;
  margin-inline-end: 5px;
  font-weight: normal;
  color: var(--cx-color-text);
}
cx-configurator-product-title:not(:empty) .cx-general-product-info a:hover {
  text-decoration: none;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info a cx-icon {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  align-self: center;
}
@media (max-width: 575.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info a {
    margin-block-start: -20px;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info a .cx-toggle-details-link-text {
  text-decoration: underline;
}
@media (max-width: 575.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info a .cx-toggle-details-link-text {
    display: none;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-block-start: -25px;
  margin-inline-end: 5px;
  font-weight: normal;
  color: var(--cx-color-text);
  border: none;
  background-color: transparent;
  outline-offset: 0px;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info button cx-icon {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  align-self: center;
}
@media (max-width: 575.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info button {
    margin-block-start: -20px;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info button .cx-toggle-details-link-text {
  text-decoration: underline;
}
@media (max-width: 575.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info button .cx-toggle-details-link-text {
    display: none;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details {
  inline-size: 100%;
  max-block-size: 0;
  overflow: hidden;
  display: flex;
  font-size: var(--cx-font-small, 0.8rem);
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details.open {
  max-block-size: 400px;
}
@media (max-width: 767.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details.open {
    padding-inline-start: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details.open {
    padding-inline-start: 32px;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-image {
  margin-block-start: 15px;
  margin-block-end: 0px;
  max-inline-size: 100px;
  width: 3rem;
  height: 3rem;
}
@media (min-width: 768px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-image {
    height: 5rem;
    min-width: 5rem;
    width: 5rem;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-image cx-media {
  background-color: var(--cx-color-light);
  height: 100%;
  width: 100%;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-image img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content {
  display: flex;
  flex-direction: column;
  margin-inline-start: 15px;
  margin-inline-end: 0px;
  margin-block-start: 15px;
  margin-block-end: 0px;
}
@media (max-width: 767.98px) {
  cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content {
    width: 70%;
  }
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-detail-title,
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-price {
  font-weight: bold;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-detail-title span,
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-code span,
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-description span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-kb-key-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-kb-key-details .cx-kb-pair .cx-label {
  text-transform: uppercase;
  font-weight: bold;
  padding-inline-end: 5px;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-kb-key-details .cx-kb-pair:not(:first-child) {
  padding-inline-start: 15px;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-kb-key-details .cx-kb-pair .cx-value {
  padding-inline-start: 5px;
  padding-inline-end: 15px;
}
cx-configurator-product-title:not(:empty) .cx-general-product-info .cx-details .cx-details-content .cx-kb-key-details .cx-kb-pair:not(:last-child) {
  border-inline-end: solid 1px;
}
cx-configurator-product-title:not(:empty) .cx-ghost-general-product-info {
  height: 58px;
  margin-block-end: 15px;
  background-color: var(--cx-color-ghost);
}

cx-configurator-restart-dialog:not(:empty) {
  z-index: 3000;
}
cx-configurator-restart-dialog:not(:empty) .cx-modal-content {
  margin: auto;
}
@media (max-width: 767.98px) {
  cx-configurator-restart-dialog:not(:empty) .cx-modal-content {
    min-height: 100%;
  }
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-header {
  padding-top: 2rem;
  padding-inline-end: 1.75rem;
  padding-bottom: 1.25rem;
  padding-inline-start: 1.75rem;
  display: flex;
  position: sticky;
  background-color: var(--cx-color-inverse);
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-header .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  align-self: flex-start;
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-header.modal-header .close {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-block-start: 0px;
  padding-block-end: 0px;
  align-self: flex-start;
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 16px;
  padding-block-end: 16px;
}
@media (max-width: 767.98px) {
  cx-configurator-restart-dialog:not(:empty) .cx-dialog-body {
    padding-block-start: 20px;
    padding-block-end: 20px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
  }
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-body #cx-configurator-dialog-description {
  margin-block-start: 8px;
  margin-block-end: 16px;
}
cx-configurator-restart-dialog:not(:empty) .cx-dialog-body .btn-primary,
cx-configurator-restart-dialog:not(:empty) .cx-dialog-body .btn-secondary {
  margin-block-start: 8px;
  margin-block-end: 8px;
}

cx-configurator-show-more:not(:empty) {
  font-size: 14px;
  line-height: 1.2;
}
cx-configurator-show-more:not(:empty) button {
  background-color: transparent;
  border: none;
  display: inline-block;
  outline-offset: 0px;
  text-decoration: underline;
}
cx-configurator-show-more:not(:empty) button:hover {
  color: var(--cx-color-primary);
}

cx-configurator-tab-bar:not(:empty) {
  width: 100%;
}
@media (min-width: 1200px) {
  cx-configurator-tab-bar:not(:empty) {
    max-width: 1140px;
  }
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar,
cx-configurator-tab-bar:not(:empty) .cx-ghost-tab-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  justify-content: flex-end;
}
@media (min-width: 992px) {
  cx-configurator-tab-bar:not(:empty) .cx-tab-bar,
  cx-configurator-tab-bar:not(:empty) .cx-ghost-tab-bar {
    margin-block-end: 32px;
  }
}
@media (min-width: 992px) {
  cx-configurator-tab-bar:not(:empty) .cx-tab-bar {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);
  }
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a {
  color: black;
  cursor: pointer;
  padding: 0.5rem 0 0 0;
  text-align: center;
}
@media (min-width: 768px) {
  cx-configurator-tab-bar:not(:empty) .cx-tab-bar a {
    flex-basis: 200px;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-tab-bar:not(:empty) .cx-tab-bar a {
    inline-size: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-tab-bar:not(:empty) .cx-tab-bar a {
    inline-size: 50%;
  }
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a:after {
  content: "";
  display: block;
  block-size: 5px;
  background: var(--cx-color-primary);
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 7px;
  margin-block-end: auto;
  inline-size: 0;
  opacity: 0;
  transition: all 0.6s;
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a.active {
  font-weight: bold;
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a.active, cx-configurator-tab-bar:not(:empty) .cx-tab-bar a:hover {
  color: var(--cx-color-primary);
  text-decoration: none;
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a.active:after, cx-configurator-tab-bar:not(:empty) .cx-tab-bar a:hover:after {
  inline-size: 100%;
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a.active:after {
  opacity: 1;
}
cx-configurator-tab-bar:not(:empty) .cx-tab-bar a:not(.active):hover:after {
  opacity: 0.5;
}
cx-configurator-tab-bar:not(:empty) .cx-ghost-tab-bar {
  width: 100%;
  height: 46px;
  background-color: var(--cx-color-ghost);
}
@media (max-width: 1199.98px) {
  cx-configurator-tab-bar:not(:empty) .cx-ghost-tab-bar {
    margin-block-end: 15px;
  }
}

cx-configurator-update-message {
  position: absolute;
  width: 100%;
  z-index: 99;
}
cx-configurator-update-message div.cx-update-msg {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: fit-content;
  top: 0;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  background-color: #d1ecf1;
  position: sticky;
}
cx-configurator-update-message div.cx-update-msg.visible {
  display: flex;
}
cx-configurator-update-message div.cx-update-msg.visible cx-spinner {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
cx-configurator-update-message div.cx-update-msg.visible cx-spinner .loader-container {
  block-size: 40px;
  inline-size: 40px;
  margin: 0 auto;
}
cx-configurator-update-message div.cx-update-msg.visible cx-spinner .loader-container .loader {
  block-size: 40px;
  inline-size: 40px;
}
cx-configurator-update-message div.cx-update-msg.visible cx-spinner .loader-container .loader::before {
  block-size: 40px;
  inline-size: 40px;
  border-width: 5px;
}

cx-configuration-conflict-and-error-messages:not(:empty) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message {
  text-align: var(--cx-text-align, center);
  color: var(--cx-color, var(--cx-color-text));
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-top: 10px;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  padding-inline-start: 20px;
  line-height: var(--cx-line-height, 1.5);
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-end: 1rem;
  align-self: center;
}
@media (min-width: 992px) {
  cx-configuration-conflict-and-error-messages:not(:empty) .alert-message {
    width: 1140px;
  }
}
@media (max-width: 1199.98px) {
  cx-configuration-conflict-and-error-messages:not(:empty) .alert-message {
    width: 100%;
  }
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-icon {
  height: var(--cx-height, 25px);
  margin-inline-end: var(--cx-margin-right, 0.5rem);
  position: var(--cx-position, relative);
  display: var(--cx-display, inline-block);
  vertical-align: var(--cx-vertical-align, middle);
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-icon::before {
  position: var(--cx-position, relative);
  font-size: 18px;
  font-weight: "bold";
  text-align: var(--cx-text-align, center);
  display: var(--cx-display, inline-block);
  width: var(--cx-width, 26px);
  height: var(--cx-height, 26px);
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-error {
  background-color: #fad9d9;
  border: var(--cx-border, none);
  position: relative;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-error .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-danger));
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-invalid-warning {
  background-color: #fff1c8;
  border: var(--cx-border, none);
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message-invalid-warning .alert-icon cx-icon {
  color: var(--cx-color, var(--cx-color-warning));
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-text,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-text {
  padding-inline-end: 10px;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages.inline,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages.inline {
  flex-basis: auto;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-warning-message,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-error-message,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-warning-message,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-error-message {
  display: none;
  align-self: center;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-warning-message.open,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-error-message.open,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-warning-message.open,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-error-message.open {
  display: list-item;
  list-style: disc;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-warning-message.open:only-child,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-messages .cx-error-message.open:only-child,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-warning-message.open:only-child,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-messages .cx-error-message.open:only-child {
  display: inline;
}
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-error-toggle,
cx-configuration-conflict-and-error-messages:not(:empty) .alert-message .cx-warning-toggle {
  border: none;
  background-color: transparent;
  outline-offset: 0px;
  text-decoration: underline;
}

cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container {
  display: flex;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container a {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 10px;
  color: var(--cx-color-text);
  outline: none;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container a h3 {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  height: auto;
  min-height: 30px;
  margin-top: 5px;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container a:hover, cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container a:focus {
  color: var(--cx-color-primary);
  text-decoration: underline;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container a:focus {
  position: relative;
  z-index: 1;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container cx-media {
  align-self: center;
  width: 60%;
}
cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container cx-media.is-missing {
  min-height: 255px;
  align-self: auto;
  width: auto;
}

cx-configurator-variant-carousel:not(:empty) .cx-variant-carousel-container {
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
  margin-block-start: 15px;
  margin-block-end: 15px;
  padding-block-start: 16px;
  padding-block-end: 16px;
}

cx-configurator-overview-sidebar:not(:empty) {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-inline-end: 25px;
  padding-block-start: 5px;
  padding-block-end: 5px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar {
  display: flex;
  flex-direction: row;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--cx-color-light);
  font-size: 1.25rem;
  font-weight: 700;
  padding-block-start: 25px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button {
  color: black;
  cursor: pointer;
  padding: 0.5rem 0 0 0;
  text-align: start;
  border: none;
  background-color: transparent;
}
@media (min-width: 768px) {
  cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button {
    flex-basis: 200px;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button {
    inline-size: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button {
    inline-size: 50%;
  }
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button:after {
  content: "";
  display: block;
  block-size: 5px;
  background: var(--cx-color-primary);
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 7px;
  margin-block-end: auto;
  inline-size: 0;
  opacity: 0;
  transition: all 0.6s;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button.active {
  font-weight: bold;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button.active, cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button:hover {
  color: var(--cx-color-primary);
  text-decoration: none;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button.active:after, cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button:hover:after {
  inline-size: 100%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button.active:after {
  opacity: 1;
}
cx-configurator-overview-sidebar:not(:empty) .cx-menu-bar button:not(.active):hover:after {
  opacity: 0.5;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-bar,
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-item-title {
  background-color: var(--cx-color-ghost);
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-bar {
  width: 100%;
  height: 58px;
  margin-block-end: 8px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 {
  margin-block-start: 22px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-item:nth-child(1) {
  width: 95%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level2 {
  margin-inline-start: 55px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level2 .cx-ghost-menu-item:nth-child(1) {
  width: 65%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level2 .cx-ghost-menu-item:nth-child(2) {
  width: 75%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level2 .cx-ghost-menu-item:nth-child(3) {
  width: 55%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level2 .cx-ghost-menu-item:nth-child(4) {
  width: 65%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level3 {
  margin-inline-start: 55px;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level3 .cx-ghost-menu-item:nth-child(1) {
  width: 65%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level3 .cx-ghost-menu-item:nth-child(2) {
  width: 75%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level3 .cx-ghost-menu-item:nth-child(3) {
  width: 55%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-level1 .cx-ghost-menu-level3 .cx-ghost-menu-item:nth-child(4) {
  width: 65%;
}
cx-configurator-overview-sidebar:not(:empty) .cx-ghost-menu .cx-ghost-menu-item-title {
  height: 30px;
  margin-block-end: 8px;
  padding-block-end: 5px;
}

cx-configurator-form, cx-configurator-conflict-solver-dialog:not(:empty) {
  width: 100%;
}
cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 12px;
  padding-block-end: 12px;
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-block-start: 12px;
    padding-block-end: 12px;
    padding-block-end: 24px;
  }
}
cx-configurator-form .cx-ghost-attribute em, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em {
  display: flex;
  flex-direction: row;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 10px;
  padding-block-end: 0px;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
}
cx-configurator-form .cx-ghost-attribute em fieldset, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset {
  width: 100%;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair {
  width: 80%;
  padding-inline-end: 10px;
  line-break: normal;
}
cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-price {
  width: 20%;
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-label-pair,
  cx-configurator-form .cx-ghost-attribute em fieldset .form-check .cx-value-price,
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute em fieldset .form-check .cx-value-price {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-form .cx-ghost-attribute, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-block-start: 12px;
    padding-block-end: 12px;
  }
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-title,
cx-configurator-form .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description,
cx-configurator-form .cx-ghost-attribute .cx-ghost-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-price,
cx-configurator-form .cx-ghost-attribute .cx-ghost-label,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-label {
  height: 30px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-title,
cx-configurator-form .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description,
cx-configurator-form .cx-ghost-attribute .cx-ghost-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-price,
cx-configurator-form .cx-ghost-attribute .cx-ghost-label,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-label,
cx-configurator-form .cx-ghost-attribute .cx-ghost-text,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-text,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-icon,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-icon,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-price {
  background-color: var(--cx-color-ghost);
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header,
cx-configurator-form .cx-ghost-attribute .cx-ghost-description-box,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-description-box,
cx-configurator-form .cx-ghost-attribute .cx-ghost-body,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body,
cx-configurator-form .cx-ghost-attribute .cx-ghost-radiobutton-value,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-radiobutton-value,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-label-pair,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-label-pair,
cx-configurator-form .cx-ghost-attribute .cx-ghost-value-price,
cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-value-price {
  display: flex;
  flex-direction: row;
  align-items: center;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-block-end: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-title, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-title {
  width: 30%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-icon, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-icon {
  width: 30px;
  border-radius: 50%;
  margin-inline-start: 10px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box {
  flex: 0 0 100%;
  margin-block-start: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box .cx-ghost-description, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-header .cx-ghost-description-box .cx-ghost-description {
  width: 60%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-block-end: 15px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-text, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-text {
  width: 70%;
  height: 72px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-price {
  width: 10%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value {
  flex: 0 0 100%;
  justify-content: space-between;
  margin-block-start: 8px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair {
  width: 80%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-value-icon, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-value-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-label-pair .cx-ghost-label {
  margin-inline-start: 10px;
  height: 30px;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(1) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(1) .cx-ghost-label {
  width: 20%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(2) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(2) .cx-ghost-label {
  width: 55%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(3) .cx-ghost-label, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value:nth-child(3) .cx-ghost-label {
  width: 30%;
}
cx-configurator-form .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-price, cx-configurator-conflict-solver-dialog:not(:empty) .cx-ghost-attribute .cx-ghost-body .cx-ghost-radiobutton-value .cx-ghost-value-price {
  width: 10%;
  align-items: flex-end;
}

cx-configurator-conflict-solver-dialog:not(:empty) {
  z-index: 2000;
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container {
  height: auto !important;
  margin-block-start: 80px;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
@media (min-width: 992px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container {
    max-width: 992px !important;
    min-width: 992px !important;
  }
}
@media (min-width: 1200px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container {
    max-width: 1140px !important;
    min-width: 1140px !important;
  }
}
@media (max-width: 991.98px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content {
    height: 100vh !important;
  }
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-header {
  outline: 0;
  padding-inline-start: 16px;
  padding-inline-end: 0px;
  padding-block-start: 12px;
  padding-block-end: 0px;
}
@media (max-width: 991.98px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-header {
    padding-inline-end: 0px;
    padding-block-end: 5px;
  }
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-header .cx-dialog-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  align-self: flex-start;
}
@media (max-width: 991.98px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-header .close {
    padding-block-end: 0px;
  }
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-body {
  padding: 0;
}
@media (max-width: 991.98px) {
  cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-body {
    padding-block-end: 5px;
  }
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-body .cx-msg-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 0px;
  padding-inline-end: 5px;
  margin-block-start: 12px;
  margin-block-end: 12px;
  background-color: rgb(245, 206, 206);
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-body .cx-msg-warning cx-icon {
  color: var(--cx-color-danger);
  align-self: center;
  font-size: 30px;
  padding-inline-start: 15px;
  padding-inline-end: 18px;
  padding-block-start: 5px;
  padding-block-end: 15px;
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-dialog-body cx-configurator-conflict-description {
  padding-inline-start: 0px;
  padding-block-start: 0px;
  padding-block-end: 0px;
}
cx-configurator-conflict-solver-dialog:not(:empty) .cx-modal-container .cx-modal-content .cx-modal-footer {
  display: flex;
  justify-content: center;
  margin-block-start: 15px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 16px;
  padding-block-end: 16px;
}

cx-page-layout.VariantConfigurationOverviewTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: auto;
}

cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewBottombar cx-configurator-add-to-cart-button {
  z-index: 10;
}
cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewContent,
cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewNavigation {
  height: fit-content;
}
@media (min-width: 992px) {
  cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewNavigation {
    max-inline-size: 30%;
  }
  cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewContent {
    max-inline-size: 70%;
  }
}
@media (min-width: 1200px) {
  cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewNavigation {
    max-inline-size: 342px;
  }
  cx-page-layout.VariantConfigurationOverviewTemplate cx-page-slot.VariantConfigOverviewContent {
    max-inline-size: 798px;
  }
}

cx-page-layout.VariantConfigurationTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: auto;
}
cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigContent,
cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigMenu {
  height: fit-content;
}
@media (min-width: 992px) {
  cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigMenu {
    max-inline-size: 30%;
  }
  cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigContent {
    max-inline-size: 70%;
  }
}
@media (min-width: 1200px) {
  cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigMenu {
    max-inline-size: 342px;
  }
  cx-page-layout.VariantConfigurationTemplate cx-page-slot.VariantConfigContent {
    max-inline-size: 798px;
  }
}

cx-page-layout.CpqConfigurationTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: auto;
}
cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigContent,
cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigMenu {
  height: fit-content;
}
@media (min-width: 992px) {
  cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigMenu {
    max-inline-size: 30%;
  }
  cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigContent {
    max-inline-size: 70%;
  }
}
@media (min-width: 1200px) {
  cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigMenu {
    max-inline-size: 342px;
  }
  cx-page-layout.CpqConfigurationTemplate cx-page-slot.CpqConfigContent {
    max-inline-size: 798px;
  }
}

cx-page-layout.CpqConfigurationOverviewTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: auto;
}

cx-configurator-textfield-input-field {
  display: flex;
  flex-direction: column;
  margin-inline-start: 17px;
  padding-block-start: 10px;
}
cx-configurator-textfield-input-field label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-block-start: 10px;
}
cx-configurator-textfield-input-field .form-group {
  margin-block-end: 0.5rem;
}
@media (min-width: 768px) {
  cx-configurator-textfield-input-field label,
  cx-configurator-textfield-input-field .form-group {
    inline-size: 75%;
  }
}
@media (max-width: 767.98px) {
  cx-configurator-textfield-input-field label,
  cx-configurator-textfield-input-field .form-group {
    inline-size: 100%;
  }
}

cx-configurator-textfield-input-field-readonly {
  display: flex;
  flex-direction: column;
  margin-inline-start: 17px;
  padding-block-start: 10px;
}
cx-configurator-textfield-input-field-readonly label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-block-start: 10px;
}

cx-configurator-textfield-form {
  display: flex;
  flex-direction: column;
}
cx-configurator-textfield-form .cx-attribute {
  padding: 6px 40px;
  background-color: var(--cx-color-background);
}

cx-configurator-textfield-add-to-cart-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  justify-content: flex-end;
}
@media (min-width: 576px) {
  cx-configurator-textfield-add-to-cart-button .cx-add-to-cart-btn {
    width: 50%;
  }
}

cx-page-layout.TextfieldConfigurationTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 2rem 0 2rem 0;
  max-width: 1140px;
  margin: auto;
}
@media (min-width: 992px) {
  cx-page-layout.TextfieldConfigurationTemplate cx-page-slot.TextfieldConfigContent {
    max-width: 75%;
  }
}