@mixin fill-full-background($z-index: 0) {
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100vw;
    transform: translateX(-50%);
    background-color: #F5F5F5;
    z-index: $z-index !important;
  }
}

@mixin display-flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}