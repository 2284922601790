@keyframes zoom {
  0% {
    transform: scale(0.8);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}