cx-product-carousel a {
	text-align:initial;
}

cx-carousel, generac-carousel {
	h2 {
		margin: 52px 0 1rem;

		@media(max-width: $screen-xs-max) {
			margin: 1rem 0 1rem;
		}
	}

	.carousel-panel .slides .slide .item.active {
		z-index: unset;
	}

	.indicators button {
		background: transparent;

		.cx-icon {
			background: transparent;
			border: none;
			color: $light-grey;
			padding: 0 !important;
		}

		&[disabled] {
			color: $primary;

			.cx-icon {
				background: transparent;
			}
		}
	}

	.carousel-panel .slides .slide:not(.active) {
		display: none;
	}

	.next,
	.previous {
		color: $light-grey;

		&:not(:disabled):hover {
			color: $primary;
		}
	}

	cx-product-grid-item {
		position: relative;
		padding: 0 10px;

		.cx-product-image-container {
			padding: 0;

			.cx-product-image {
				height: 200px;
				margin: 0 !important;
			}

			cx-media.is-missing {
				min-height: 200px;
				width: 100%;
			}
		}

		cx-add-to-cart {
			margin: 1rem .5rem 0;
			margin-top: 0px;
			.stock-info {
				display: none;
			}
		}

		.sp-note-container {
			margin: 1rem 0.5rem 0 !important;
    		width: auto !important;
		}
	}
}