cx-verify-address {
  background-color: rgba(0, 0, 0, 0.5);

  .cx-modal-content {
    max-height: 90vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .selected-block {
    img {
      margin-right: 5px;
      margin-bottom: 3px;
    }
  
    span {
      color: #D86018;
    }
  }

  .error-description {
    display: block;
    .cx-dialog-item {
      align-items: center;
      display: flex;
      background-color: $warning-background;
      border-radius: 10px;
      padding: 1rem 2rem;

      span {
        margin-left: 0.5rem;
      }

      .close {
        align-self: center;
        font-size: 30px;
        margin-left: auto;
      }
    }
  }

  .error-description-hidden {
    display: none;
  }

  .suggested-address {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .entered {
    border-top: 1px solid rgb(204, 204, 204);
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .suggested-wrapper {
      font-weight: 400;
      line-height: 16px;
      display: flex;
      flex-direction: column;

      .suggested-wrapper-title {
        font-size: 14px;
      }

      .suggested-desc {
        color: #666666;
        font-size: 12px;
      }
    }
  }

  .cx-dialog-header {
    padding-top: 2rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.85rem;
    padding-inline-start: 1.75rem;
    border-width: 0;
    display: flex;
  }

  .cx-dialog-title {
    @include type('3');
    align-self: flex-start;
  }

  .cx-dialog-body {
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      padding: 0;
      background-color: var(--cx-color-inverse);
    }
    .cx-remove-btn {
      max-width: 100%;
      padding: 0;
      flex: 0 0 100%;
    }
  }

  .cx-dialog-item {
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }

    .cx-image-container {
      a {
        padding: 0;
        max-width: 65px;
      }
    }

    .cx-compact {
      .cx-info {
        @include media-breakpoint-up(md) {
          padding: 0 25px;
        }
      }

      .cx-price,
      .cx-quantity,
      .cx-total {
        .cx-label {
          font-weight: var(--cx-font-weight-normal);
        }
      }

      .cx-actions {
        justify-content: flex-start;
      }
    }
  }

  .cx-dialog-separator {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: var(--cx-color-light);
  }

  .cx-dialog-actions {
    display: flex;
    width: 100%;
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      border-width: 0;
      padding: 1.875rem;
    }
  }

  .cx-dialog-promotions {
    padding: 0 0 1.25rem 0;
  }

  .cx-dialog-total {
    @include type('4');
    display: flex;
    justify-content: space-between;
    padding: 0 0 1.25rem 0;
  }

  .cx-dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
