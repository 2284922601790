@import 'variables';

main {
  cx-page-layout.MultiStepCheckoutSummaryPageTemplate {
    @media(max-width: $screen-sm-max) {
      padding-top: 0;
    }

    cx-page-slot.BodyContent {
      @media(min-width: $screen-md) {
        flex: 0 69%;
      }
    }

    cx-page-slot.SideContent {
      background: $grey-background;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 40px;

      @media(min-width: $screen-md) {
        flex: 0 0 28%;
        margin-left: 20px;
        height: fit-content;
        flex-direction: row;

        &:has(cx-place-order) {
          position: sticky;
          top: 0;
        }
      }

      cx-checkout-order-summary {
        @media(max-width: $screen-md-max) {
          min-width: 100%;
        }

        cx-order-summary {
          padding: 0;
        }
      }

      cx-schedule-replenishment-order,
      cx-place-order {
        padding-inline-start: 0;
      }

      cx-place-order {
        padding-bottom: 0;
        .form-check {
          margin-bottom: 0;
        }
      }
    }

    cx-checkout-progress-mobile-bottom,
    cx-checkout-progress-mobile-top {
      @media(max-width: $screen-md-max) {
        .cx-media {
          margin-bottom: 30px;

          .cx-list-media {
            padding: 0;
            font-weight: 500;
            font-size: 19px;

            div {
              color: $dark-grey;
            }

            &.is-active {
              div {
                color: $primary;
              }
            }

            .btn-link {
              font-weight: 400;
            }
          }
        }
      }
    }

    cx-checkout-progress {
      .cx-nav .cx-item {
        padding-bottom: 0.875rem;

        .cx-link {
          font-size: 13px;
          font-weight: 400;
  
          &.active {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.form-check-label {
  cursor: pointer;
}

.offline-badge {
  position: relative;
  background-color: #F4D6C3;
  border-radius: 50px;
  padding: 10px;
  color: $primary;
  display: flex;

  .offline {
    font-size: 16px;
    margin-right: 10px;
  }
}
