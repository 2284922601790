@import "variables";
@import "mixins";

cx-storefront > footer {
  margin-top: 54px;
}

footer {
  .footer {
    background: transparent;
    color: $dark-grey;
    border-top: 4px solid $primary;
    max-width: var(--cx-page-width-max);
    margin: auto;
    border-top: none !important;
    z-index: 1;

    > * {
      align-self: center;
      display: block;
      flex: none;
    }

    .logo-img {
      width: 165px;
    }

    cx-footer-navigation {
      background-color: inherit !important;

      a {
        font: 400 14px/20px $primary-font;
        display: flex;
        align-items: center;
      }

      cx-navigation-ui {
        background-color: inherit !important;

        > nav > ul > li {
          margin: 0;
        }
      }
    }

    cx-anonymous-consent-open-dialog {
      margin: 0;
      position: relative;
      @include fill-full-background;

      .btn-link {
        color: $dark-grey;
        font: 400 14px/20px $primary-font;
        margin: auto;
        min-width: 138px;
        text-align: left;
        z-index: 1;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
    }

    cx-paragraph {
      background: $white-background;
      display: flex;
      justify-content: center;

      div {
        text-align: center;
        padding: 15px;
        font: 300 16px/20px $primary-font;
        color: $black;

        @media(max-width: $screen-sm-max) {
          max-width: 280px;
        }
      }
    }
  }
}

@media(max-width: $screen-sm-max) {
  cx-storefront > footer {
    margin-top: 27px;
  }
}
