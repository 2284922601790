.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, cx-close-account-modal .cx-close-account-modal {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.show .cx-close-account-modal {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.modal-static .cx-close-account-modal {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable .cx-close-account-modal-container {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-dialog-scrollable .cx-close-account-modal-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-close-account-modal .cx-close-account-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before, cx-close-account-modal .cx-close-account-modal::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-centered.modal-dialog-scrollable .cx-close-account-modal-container, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal .modal-content, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal .cx-close-account-modal-container {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal::before {
  content: none;
}

.modal-content, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, cx-close-account-modal .cx-close-account-modal {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable .cx-close-account-modal-container {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered, cx-close-account-modal .cx-close-account-modal {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before, cx-close-account-modal .cx-close-account-modal::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: "bold";
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-y: initial;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

cx-login {
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-login > a {
    padding: 1rem;
    display: block;
  }
}
cx-login .cx-login-greet {
  color: var(--cx-color-text);
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 991.98px) {
  cx-login .cx-login-greet {
    color: var(--cx-color-text);
    padding: 0.5rem 1rem 0;
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.6);
  }
}
@media (min-width: 992px) {
  cx-login #account-nav nav > ul > li > button {
    padding-top: 4px;
    padding-bottom: 0;
  }
}

cx-login-form.user-form cx-spinner {
  display: none;
}
cx-login-form.user-form button {
  flex: 100%;
}

.cx-my-account-v2-user {
  border: 1px solid var(--cx-color-medium);
  width: 250px;
  padding: 20px 5px 5px 25px;
  gap: 40px;
  height: 120px;
  margin: 2rem 0rem 0rem 0rem;
}
.cx-my-account-v2-user .cx-name .cx-sign-out {
  text-decoration: underline;
}

cx-close-account {
  display: flex;
  justify-content: center;
}
cx-close-account .cx-info {
  margin: 0 0 3em 0;
}
cx-close-account .cx-btn-group button {
  min-width: 100%;
  text-transform: lowercase;
}
cx-close-account .cx-btn-group button:first-line {
  text-transform: capitalize;
}
cx-close-account .cx-btn-group button:first-child {
  margin-inline-end: 1em;
}
@media (max-width: 767.98px) {
  cx-close-account .cx-btn-group a:first-child {
    margin: 0 0 1em 0;
  }
}
cx-close-account ul {
  padding-inline-start: 20px;
}

cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header {
  display: flex;
  justify-content: space-between;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header .cx-close-account-modal-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-confirmation {
  margin-bottom: 0px;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer {
  display: flex;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button {
  flex: 0 0 calc(50% - 0.5rem);
  text-transform: lowercase;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-line {
  text-transform: capitalize;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

cx-register form a {
  text-decoration: underline;
}
cx-register form .cx-login-link {
  margin: 1rem 0 0;
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
  .user-form {
    width: 50%;
    --cx-max-width: 50%;
  }
}
.user-form form {
  display: contents;
}
.user-form form label {
  all: unset;
  flex: 100%;
  margin-bottom: 0.375rem;
}
.user-form button,
.user-form a {
  flex: 0 0 calc(50% - 10px);
  margin-top: 0.5rem;
}