split-payment-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .split-payment-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;
    max-width: 60vw;

    .split-payment-dialog-container {
      @extend .modal-content;

      border-radius: 12px;

      .split-payment-dialog-header {
        border-bottom: none;
        padding: 1rem 2rem 0;

        .modal-title {
          color: $secondary;
          font-size: 22px;
          font-weight: 700;
          line-height: 32px;
        }

        .split-payment-dialog-close {
          font-size: 1.5rem;
          margin-left: auto;
          padding: 0;
          margin: 0;
        }
      }

      .split-payment-dialog-body {
        @extend .modal-body;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        padding-top: 10px;

        .cx-dialog-item {
          color: $black;

          .cx-dialog-item-description {
            border: 1px solid $border-grey;
            border-radius: 6px;
            margin-top: 20px;
            max-height: 273px;
            overflow-y: auto;

            cx-custom-cart-item {
              .customcartitem {
                padding: 0;
                border-bottom: 1px solid $border-grey;
                margin: 0.5rem 1rem;

                .customcartitem-block-item {
                  flex-direction: column-reverse;
                  display: flex;
                }

                .customcartitem-block-qty,
                .customcartitem-block-total {
                  display: none;
                }
              }



              &:last-of-type .customcartitem {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    .split-payment-dialog-footer {
      border-top: 1px solid $border-grey;

      &>.cx-dialog-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        .btn {
          flex: 0 0 calc(35% - 0.5rem);
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 24px;
          padding: 12px;

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }
        }

        .btn-secondary:disabled {
          background-color: $white;
          border: 1px solid $border-grey;
          color: $light-grey;
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;

          .btn:first-child {
            margin: 0 !important;
          }

          .btn:last-child {
            margin: 0 0 16px 0 !important;
          }
        }
      }
    }
  }
}