delete-saved-address-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .delete-saved-address-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    .delete-saved-address-dialog-container {
      @extend .modal-content;

      border-radius: 12px;
      padding: 32px;

      .delete-saved-address-dialog-header {
        border-bottom: none;
        padding: 0;

        .close {
          color: $dark-grey;
          font-size: 24px;
          opacity: 1;
          right: -10px;
        }

        .delete-saved-address-dialog-title {
          font-size: var(--cx-font-size, 1.375rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: var(--cx-line-height, 1.2222222222);
        }
      }

      .delete-saved-address-dialog-body {
        @extend .modal-body;

        padding: 8px 0 24px;

        p {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2rem;
          text-align: center;
          margin-bottom: 0;
        }
      }

      .delete-saved-address-dialog-footer {
        display: flex;

        button {
          flex: 0 0 calc(50% - 0.5rem);

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;

          button:first-child {
            margin: 0 !important;
          }
  
          button:last-child {
            margin: 0 0 16px 0 !important;
          }
        }
      }
    }
  }
}