@import "variables";

body {
  .btn {
    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    &-primary {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      border-radius: 6px;
      border-width: 0px;
      font: 500 18px/24px $primary-font;

      &:hover,
      &:not(:disabled):not(.disabled):active {
        background-color: $primary;
        border-color: $primary;
        filter: contrast(1.1) brightness(1.1);
      }

      &:disabled, &.disabled {
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
      }

      &:focus {
        background-color: $primary;
        border-color: $primary;
      }
    }

    &-action,
    &-secondary {
      color: $primary;
      background-color: transparent;
      border: 1px solid $primary;
      border-radius: 6px;

      &:hover,
      &:not(:disabled):not(.disabled):active {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        filter: contrast(1.1) brightness(1.1);
      }

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .cx-action-link, .btn-tertiary {
    color: $black;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0;
    &:hover {
      color: var(--cx-color-primary);
    }
  }

  .cx-action-btn-link {
    color: var(--cx-color-primary);
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0;
    &:hover {
      color: $black;
      cursor: pointer;
    }
  }
}
