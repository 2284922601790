create-or-edit-favorites-list-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .create-or-edit-favorites-list-dialog {
    @extend .modal-dialog;
    @extend .modal-dialog-centered;

    .create-or-edit-favorites-list-dialog-container {
      @extend .modal-content;

      border-radius: 12px;
      padding: 32px;

      .create-or-edit-favorites-list-dialog-header {
        border-bottom: none;
        padding: 0;

        .close {
          color: $dark-grey;
          font-size: 24px;
          opacity: 1;
          right: -10px;
        }

        .create-or-edit-favorites-list-dialog-title {
          font-size: var(--cx-font-size, 1.375rem);
          font-weight: var(--cx-font-weight-semi);
          line-height: var(--cx-line-height, 1.2222222222);
        }
      }

      .create-or-edit-favorites-list-dialog-body {
        @extend .modal-body;

        padding: 32px 0;

        .list-name-label {
          line-height: normal;
          margin-bottom: 15px;
        }

        .list-name-input {
          border-radius: 6px;
          border: 1px solid $border-grey;
          font-size: 1rem;
          outline: none;
          padding: 9px 12px;
          width: 100%;
        }

      }

      .create-or-edit-favorites-list-dialog-footer {
        display: flex;

        button {
          flex: 0 0 calc(50% - 0.5rem);

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column-reverse;

          button:first-child {
            margin: 0 !important;
          }
  
          button:last-child {
            margin: 0 0 16px 0 !important;
          }
        }
      }
    }
  }
}