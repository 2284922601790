@import "variables";

cx-page-layout.ProductDetailsPageTemplate {
  padding: 0;

  .BottomHeaderSlot {
    cx-breadcrumb {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: initial;

      h1 {
        font-weight: 400;
        max-width: 50%;
        padding: 0px 0px 0px;
        font-size: 14px;
        margin: 0;
        height: 18px;
        color: #6c7079;
      }
    }
  }

  .Summary {
    grid-column-gap: 28px !important;
    padding-top: 12px;
    position: relative;

    generac-product-images {
      grid-column: 1;
      grid-row: 1 / span 6;
      height: fit-content;
    }

    >* {
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }

    @media (min-width: $screen-md) {
      grid-template-columns: 8fr 10fr !important;
    }

    @media (max-width: ($screen-md-max)) {
      display: grid;
      grid-template-rows: 1fr 0fr !important;
      margin-bottom: 0px !important;

      // grid-template-columns: 1fr 0;
      >* {
        grid-column: 1;
      }

      generac-product-images {
        grid-row: 1;
      }

      cx-product-intro {
        grid-row: 2;
      }

      cx-product-summary {
        grid-row: 3;

        .prices {
          max-width: max-content;
        }

        .price {
          font-size: 1.5rem;
          font-weight: 500;
          min-width: 110px;
          min-height: 33px;

          .net {
            font-size: 1rem;
            font-weight: 400;
          }
        }

        .price-msr {
          margin-left: 20px;

          .msr-amount {
            font-size: 1.25rem !important;
            font-weight: 400 !important;
          }

          span {
            font-size: 0.875rem !important;
            font-weight: 400 !important;
          }
        }
      }

      cx-add-to-cart {
        grid-row: 4;

        .quantity-add-to-cart-block {
          align-items: flex-start !important;
          display: flex !important;
          flex-direction: column !important;
          gap: 0 !important;

          .btn {
            width: 100% !important;
          }
      }

        add-to-favorites {
          display: none;
        }
      }

      generac-product-warranties {
        grid-row: 5;
      }

      cx-availability-date {
        grid-row: 6;
      }

      cx-download-link {
        grid-row: 7;
      }
    }

    &>cx-add-to-cart {
      .stock-info {
        margin-bottom: 12px;
      }

      .quantity-add-to-cart-block {
        align-items: center;
        display: grid;
        gap: 28px;
        grid-template-columns: 1fr 3fr !important;
        margin: 0;

        .quantity {
          grid-row: 1;
          margin: 0;

          label {
            font-size: 1rem;
            font-weight: 400;
            margin-top: 0;
          }

          .cx-custom-item-counter__input  {
            border: none;
          }
        }

        .btn {
          grid-row: 1;
          height: 50px;
          margin-top: 30px;
          width: auto;
        }

        .call-for-price {
          padding-top: 12px;
          position: absolute;
          width: 250px;
        }
      }

      add-to-favorites {
        position: absolute;
        top: 45px;
        right: 20px;

        .btn {
          border: 1px solid #EEEEEE;
          position: relative;

          .favorite-icon {
            position: absolute;
            top: 25%;
            left: 25%;
          }
        }

        .btn-link:focus {
          border-radius: 50%;
        }
      }

      generac-sp-ltl, generac-sp-flammable {
        display: none;
      }
    }
  }

  cx-product-intro {
    position: relative;
    padding-top: 20px;

    .code {
      font-size: 1rem;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .name {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 28px;
      width: 85%;
    }

    add-to-favorites {
      position: absolute;
      top: 20px;
      right: 0;

      @media (min-width: $screen-md-max) {
        display: none;
      }
    }

    .border-grey {
      border: 1px solid $border-grey;
      border-radius: 50%;

      cx-icon svg, .cx-icon svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  cx-page-slot {
    &.Bundles {
      max-width: var(--cx-page-width-max);
      margin: auto auto 56px auto;
      position: relative;

      @media (max-width: ($screen-md-max)) {
        margin: 24px auto 16px auto !important;
      }
    }
  }

  cx-tab-paragraph-container {
    .mob-tab-paragraph-container {
      display: none;

      .cx-tab-paragraph-content {
        background-color: transparent;

        cx-product-details-tab {
          color: $secondary;
          line-height: 24px;
        }
      }
    }

    .desktop-tab-paragraph {
      &-content {
        background-color: transparent;
      }
    }
  }

  cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cx-media img {
      height: fit-content;
    }
  }

  generac-product-images {
    display: flex;
    flex-direction: column;
    height: 100%;

    > cx-media {
      display: flex;
      height: 100%;
      width: 100%;
      position: relative;
      max-height: 360px;
      justify-content: center;
  
      img {
        object-fit: scale-down;
      }
    }

    .sp-gear-thumb {
      display: flex;
      height: 200px;
      margin: 2rem auto;

      @media (max-width: $screen-md) {
        width: 90vw;
      }
    }

    cx-media {
      outline: none;
      text-align: center;
      cursor: zoom-in;
  
      &.is-missing {
        min-height: 30vh;
      }
    }

    .thumbs {
      cx-media {
        border: 2px solid var(--cx-color-light);
        margin: 0.5vw;
        padding: 0.5vw;
        aspect-ratio: 1;
        display: flex;
        cursor: pointer;

        img {
          margin: auto;
        }

        &.is-missing {
          min-height: 80px;
        }

        &.is-active,
        &:hover {
          border-color: var(--cx-color-primary);
        }
      }
    }
  }

  cx-product-attributes {
    .specifications-container {
      display: flex;

      table {
        margin: 0;
        flex-direction: column;

        tr {
          display: flex;

          &:nth-child(even) {
            background-color: $grey-background;
          }
        }

        td {
          line-height: 24px;
          width: 50%;
          vertical-align: center;
          padding: 24px 30px !important;
          display: flex;

          .specifications-table-cell {
            width: 50%;
          }
        }

        ul {
          margin: 0;

          li {
            line-height: 24px;
            vertical-align: center;
          }
        }
      }
    }
  }

  .code {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 24px;
  }

  cx-tab-paragraph-container {

    &>div,
    &>.tab-paragraph-container {
      .description-container {
        margin-top: 30px;
      }

      &>button {

        &:hover,
        &.active {
          color: $primary;

          &>span.accordion-icon {
            &:before {
              color: $primary;
              font-weight: 400;
            }
          }
        }

        &:after {
          background: $primary;
          height: 3px;
        }
      }

      &>div {
        padding: 0;

        .specifications {
          padding: 0;
        }
      }
    }
  }

  cx-product-summary {
    .prices {
      .price {
        font-size: 1.5rem !important;
        font-weight: 500 !important;
      }
      .price-msr {
        margin-left: 20px !important;

        .msr-amount {
          font-size: 1.25rem !important;
          font-weight: 400 !important;
        }

        span {
          color: $dark-grey !important;
        }
      }
    }
    p {
      margin: 0;
    }

    generac-sp-ltl, generac-sp-flammable {
      cx-info-message {
        position: relative;
        right: -60px;
        top: 20px;

        .info-message-container.position-left {
          left: -95px;

          &::after {
            left: 5px;
          }
        }

        @media (min-width: $screen-md-max) and (max-width: $screen-lg-max) {
          .info-message-container.position-right {
            right: 10px;

            &::after {
              right: 45px;
            }
          }
        }

        cx-icon {
          display: none;
        }
      }
    }

    generac-sp-flammable cx-info-message > .info-message-container {
      max-width: 230px;
    }
  }

  cx-add-to-cart {
    .quantity {
      margin: 0 0 30px;

      .cx-counter-stock {
        display: flex;
        height: 50px;
      }
    }

    button[type=submit] {
      padding: 7px 12px;
    }

    add-to-favorites {
      .button-text {
        color: $dark-grey;
        font-size: 1.125rem !important;
        font-weight: 500;
        margin-left: 12px;
        vertical-align: inherit;
      }

      .button-remove>.button-text {
        color: $primary;
      }
    }
  }

  cx-product-image-zoom-trigger {
    margin-top: 30px;

    .cx-action-link {
      color: #212738;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .cx-action-link {
    text-decoration: none;

    &:hover {
      color: $primary;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .cx-counter-stock {
    .info {
      padding: 6px 16px;
      font: 400 16px/24px $primary-font;
      border-radius: 6px;
      color: $red;
      display: flex;
      align-items: center;
      margin-left: 40px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      &:not(:only-child) {
        display: flex;
        align-items: center;
        position: static;
        transform: none;
        margin: 0 0 0 50px;

        @media(max-width: $screen-xs-max) {
          margin: 0 0 0 auto;
        }
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    cx-tab-paragraph-container {
      .mob-tab-paragraph-container {
        display: block;
      }

      .desktop-tab-paragraph-container {
        display: none;
      }
    }

    cx-product-attributes {
      .specifications-container {
        display: flex;
        flex-direction: column;

        table {
          tr {
            display: flex;
            flex-direction: column;

            &:nth-child(even) {
              background-color: transparent;
            }
          }

          td {
            width: 100%;

            .specifications-table-cell {
              width: 100%;
            }

            &:nth-child(even) {
              background-color: $grey-background;
            }
          }
        }
      }
    }

    &>div,
    &>.tab-paragraph-container {
      &>div {}
    }

    .cx-product-summary {
      padding: 0 15px;
    }
  }

  @media(max-width: $screen-md-max) {
    cx-product-image-zoom-view .cx-main-image-group .cx-default-image-zoom {
      height: 300px;
    }
  }

  @media(max-width: $screen-xs-max) {
    padding: 0;
  }

  .ProductHeaderMenu {
    background: $white;
    border-bottom: 1px solid $border-grey;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 9999;
    padding: 12px 0 0 !important;
  }

  generac-product-warranties {
    grid-column: 2;
    grid-row: 4;

    .product-warranties-title {
      cx-info-message {
        .info-message-container.position-right {
          left: 45px !important;
          bottom: 42px;

          .regular-icon {
            display: none;
          }

          @media(max-width: $screen-xs-max) {
            right: -6px !important;
          }

          &:after {
            right: calc(50% - 36px / 2);
          }
        }
      }
    }

    generac-product-warranty-item {
      .name {
        @media (min-width: $screen-lg) {
          max-width: 300px !important;
        }

        @media (max-width: $screen-lg-max) {
          max-width: 200px !important;
        }

        @media (max-width: $screen-md-max) {
          max-width: 325px !important;
        }

        @media (max-width: $screen-sm) {
          max-width: 385px !important;
        }
      }

      cx-media img {
        max-width: 44px;
      }

      cx-add-to-cart {
        generac-product-badge {
          display: none;
        }
      }
    }

    add-to-favorites {
      display: none;
    }

    cx-info-message {
      .info-message-container.position-right {
        right: -20px !important;
      }
    }

    cx-add-to-cart {
      align-self: center;
      margin-left: 10px;

      .stock-info {
        display: none;
      }
    }
  }

  .RecentlyReviewed {
    max-width: var(--cx-page-width-max);
    margin: auto;
  }
}